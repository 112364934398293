import React, { useState, useRef } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { t, withTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import Persona from "persona";
import { personaKycVerificationStart } from '../../../store/actions/KycDocumentAction';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';



const NewKycIndex = (props) => {
  const history = useHistory();

  const [options, setOptions] = useState({
    templateId: configuration.get("configData.persona_kyc_template_id"),
    environment: configuration.get("configData.persona_kyc_environment"),
    host: configuration.get("configData.persona_kyc_environment"),
  });

  const embeddedClientRef = useRef(null);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !props.persona.loading && Object.keys(props.persona.data).length > 0) {
      history.push("/");
    }
    setSkipRender(false);
  }, [props.persona]);

  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (data) => {
        console.log(data);
        console.log(
          `Sending finished inquiry ${data.inquiryId} to backend`,
          data
        );
        props.dispatch(
          personaKycVerificationStart({
            inquiry_id: data.inquiryId,
            kyc_status: data.status,
          })
        );
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  if (!options.templateId) {
    return null;
  }

  const handleKycModal = () => {
    if (embeddedClientRef.current != null) {
      embeddedClientRef.current.open();
    } else {
      createClient();
    }
  };

  return (
    <>
      <div className="upload-doc-sec main-wrapper text-center">
        <Container>
        <div className="new-upload-kyc-card">
          <div className="new-upload-kyc-img-sec">
              <Image
                src={
                window.location.origin +
                "/assets/images/new-kyc-img.svg"
                }
                className="new-upload-kyc-img"
              />
          </div>
          <Button className="btn btn-success mt-4" onClick={handleKycModal}>
            {t("complete_kyc_verification")}
          </Button>
        </div>
        </Container>
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  persona: state.kycDocument.persona,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(NewKycIndex));