import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";

const LandingHomeBanner = (props) => {

  return (
    <>
      <div className="home-banner-img-sec">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="resp-mrg-btm-xs">
              <div className="home-banner-content">
                <h1>Oh, <span>Hello</span></h1>
                <p>Careers are hard. Workplace stress sucks.</p>
                <p>OhHello is THE marketplace for curious professionals seeking 1:1 advice and inspiration, from our experienced community, all while giving back to those in need.</p>
                <p> Real experts. Real advice. No fluff.</p>
                <div className="banner-btn-sec">
                  <Link to={localStorage.getItem("userId") ? "/browse/featured" : "/signup"} className="landing-theme-btn">
                    {t("become_a_mentee")}
                  </Link>
                  <Link to="/enroll" className="open-header-btn" >{t("become_a_mentor")}</Link>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="home-banner-img-card">
                <Image
                  src={window.location.origin + `/assets/images/landing/banner-img.png`}
                  className="home-banner-img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation(LandingHomeBanner);
