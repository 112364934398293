import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import notifyReducer from "react-redux-notify";
import CardsReducer from "./CardsReducer";
import BillingAccountReducer from "./BillingAccountReducer";
import ErrorReducer from "./ErrorReducer";
import BookingReducer from "./BookingReducer";
// import VerificationDocumentReducer from "./VerificationDocumentReducer";
import NotificationReducer from "./NotificationReducer";
import WalletReducer from "./WalletReducer";
import TransactionReducer from "./TransactionReducer";
import PageReducer from "./PageReducer";
import PostReducer from "./PostReducer";
import FollowReducer from "./FollowReducer";
import VerificationDocumentReducer from "./VerificationDocumentReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import WithDrawReducer from "./WithDrawReducer";
import BankAccountReducer from "./BankAccountReducer";
import ModelReducer from "./ModelReducer";
import LookUpReducer from "./LookUpReducer";
import KycDocumentReducer from "./KycDocumentReducer";
import VideoCallReducer from "./VideoCallReducer";
import SearchReducer from "./SearchHistoriesReducer";
import AvailabilityReducer from "./AvailabilityReducer"

export default combineReducers({
  users: UserReducer,
  cards: CardsReducer,
  billingAccount: BillingAccountReducer,
  bookings: BookingReducer,
  errorDetails: ErrorReducer,
  notification: NotificationReducer,
  notifications: notifyReducer,
  kycDocument: KycDocumentReducer,
  subscriptions: SubscriptionReducer,
  post: PostReducer,
  follow: FollowReducer,
  docs: VerificationDocumentReducer,
  wallet: WalletReducer,
  transaction: TransactionReducer,
  page: PageReducer,
  withDraw: WithDrawReducer,
  bankAccount: BankAccountReducer,
  model: ModelReducer,
  lookup: LookUpReducer,
  videocall: VideoCallReducer,
  search: SearchReducer,
  mentorAvailability: AvailabilityReducer,
});
