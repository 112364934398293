import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    SEARCH_HISTORIES_LIST_START,
    SEARCH_HISTORIES_DELETE_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
    searchHistoriesListSuccess,
    searchHistoriesListFailure,
    searchHistoriesDeleteSuccess,
    searchHistoriesDeleteFailure,

} from "../actions/SearchHistoriesAction";
import { checkLogoutStatus } from "../actions/ErrorAction";


function* fetchSearchHistoryAPI(action) {
    try {
        const response = yield api.postMethod("search_histories_index", action.data);
        if (response.data.success) {
            yield put(searchHistoriesListSuccess(response.data.data));
        } else {
            yield put(checkLogoutStatus(response.data));
            yield put(searchHistoriesListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(searchHistoriesListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* deleteSearchHistoriesAPI(action) {
    try {
        const response = yield api.postMethod("search_histories_delete", action.data);
        if (response.data.success) {
            yield put(searchHistoriesDeleteSuccess(response.data.data));
        } else {
            yield put(checkLogoutStatus(response.data));
            yield put(searchHistoriesDeleteFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(searchHistoriesDeleteFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}


export default function* searchHistorySaga() {
    yield all([yield takeLatest(SEARCH_HISTORIES_LIST_START, fetchSearchHistoryAPI)]);
    yield all([yield takeLatest(SEARCH_HISTORIES_DELETE_START, deleteSearchHistoriesAPI)]);
}
