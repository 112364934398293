import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ScheduleAvailability.css";
import dayjs from 'dayjs';
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar, utils } from "react-modern-calendar-datepicker";
import { CalenderScheduler } from "../CustomComponents/CalenderScheduler/CalenderScheduler";
import { connect } from "react-redux";
import { t, withTranslation } from "react-multi-lang";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import moment from "moment";
import {
    fetchMentorsAvailabilityStart,
    saveMentorsAvailabilityStart,
    syncGoogleCalendarStart,
} from "../../store/actions/AvailabilityAction";
import CalenderLoader from "./CalenderLoader";

var yesterday = moment().subtract(1, "day");

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleAvailabilityIndex = (props) => {

    useEffect(() => {
        if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
    }, []);

    const [today, setToday] = useState(null);

    const formatNumber = (num) => {
        if (num < 10) return "0" + num;
        else return num;
    };

    const [skipRender, setSkipRender] = useState(true);
    const [startTime, setStartTime] = useState();
    const [startTimeFormatted, setStartTimeFormatted] = useState();
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [events, setEvents] = useState(null);
    
    useEffect(() => {
        if (!skipRender) {
            props.dispatch(fetchMentorsAvailabilityStart({ date: startTime }));
        }
    }, [startTime]);

    useEffect(() => {
        if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
            setToday(new Date(new Date().toLocaleString('en', { timeZone: props.profile.data.timezone })));
        }
    }, [props.profile]);

    useEffect(() => {
        if (today) {
            setStartTime(today.getFullYear() +
                "-" +
                formatNumber(parseInt(today.getMonth() + 1)) +
                "-" +
                formatNumber(today.getDate()));
            setStartTimeFormatted(parseInt(today.getMonth() + 1) +
                "/" +
                today.getDate() +
                "/" +
                today.getFullYear());
        }
    }, [today]);

    const startTimeOnchange = (time) => {
        setSelectedSlots([]);
        console.log(time);
        // setStartTime(dayjs(time).format("YYYY-MM-DD"));
        setStartTime(time);
        setStartTimeFormatted(dayjs(time).format("MM/DD/YYYY"));
    };

    const syncGoogleCalendar = () => {
        const script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.src = "https://apis.google.com/js/api.js";

        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.gapi) handleClientLoad();
        });

    }

    const handleClientLoad = () => {
        window.gapi.load("client:auth2", initClient);
    };

    const SCOPES =
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar";

    const openSignInPopup = () => {
        window.gapi.auth2.authorize(
            { client_id: props.profile.data.google_client_id, scope: SCOPES },
            (res) => {
                if (res) {
                    console.log(res);
                    if (res.access_token)
                        localStorage.setItem("access_token", res.access_token);

                    // Load calendar events after authentication
                    window.gapi.client.load("calendar", "v3", listUpcomingEvents);
                }
            }
        );
    }

    const initClient = () => {
        if (!localStorage.getItem("access_token")) {
            openSignInPopup();
        } else {
            // Get events if access token is found without sign in popup
            fetch(
                `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${props.profile.data.google_api_key}&orderBy=startTime&singleEvents=true`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                }
            )
                .then((res) => {
                    // Check if unauthorized status code is return open sign in popup
                    if (res.status !== 401) {
                        return res.json();
                    } else {
                        localStorage.removeItem("access_token");
                        openSignInPopup();
                    }
                })
                .then((data) => {
                    if (data?.items) {
                        setEvents(formatEvents(data.items));
                        props.dispatch(syncGoogleCalendarStart({ timeslots: JSON.stringify(formatEvents(data.items)),date: startTime,google_access_token:localStorage.getItem("access_token") }));
                    }
                });
        }
    };

    const listUpcomingEvents = () => {
        window.gapi.client.calendar.events
            .list({
                // Fetch events from user's primary calendar
                calendarId: "primary",
                showDeleted: false,
                singleEvents: true,
            })
            .then(function (response) {
                let events = response.result.items;

                if (events.length > 0) {
                    setEvents(formatEvents(events));
                    props.dispatch(syncGoogleCalendarStart({ timeslots: JSON.stringify(formatEvents(events)),date: startTime,google_access_token:localStorage.getItem("access_token") }));
                }
            });
    };

    const formatEvents = (list) => {
        return list.map((item) => ({
            title: item.summary,
            start: item.start.dateTime || item.start.date,
            end: item.end.dateTime || item.end.date,
        }));
    };

    const onClickTimeslot = (timeslot) => {
        if (selectedSlots.includes(timeslot)) {
            setSelectedSlots(
                selectedSlots.filter((x) => x.slot_id !== timeslot.slot_id)
            );
        } else {
            setSelectedSlots([...selectedSlots, timeslot]);
        }
        // let index = selectedSlots.findIndex(x => x.slot_id === timeslot.slot_id);
        // if (index != -1) {
        //     selectedSlots.splice(index, 1);
        // } else {
        //     setSelectedSlots([
        //         ...selectedSlots,
        //         timeslot
        //     ]);
        // }
    };

    const selectAllTimeSlots = () => setSelectedSlots(props.availability.data.trainer_slot);
    const unselectAllTimeSlots = () => setSelectedSlots(
        props.availability.data.trainer_slot.filter((x) => x.is_slot_booked == 1)
    );;

    const handleSubmit = (event) => {
        console.log(JSON.stringify(selectedSlots));
        event.preventDefault();
        const removedSlots = props.availability.data.trainer_slot.filter(slot => slot.is_available == 1)
            .filter(oldSlot =>
                !selectedSlots.some(selectedSlot =>
                    oldSlot.slot_id === selectedSlot.slot_id
                )
            );
        props.dispatch(
            saveMentorsAvailabilityStart({
                date: startTime,
                slots: JSON.stringify(selectedSlots),
                removed_slots: JSON.stringify(removedSlots),
            })
        );
    };

    useEffect(() => {
        if (!skipRender && !props.availability.loading && Object.keys(props.availability.data).length > 0) {
            // let slots_arr = [];
            // props.availability.data.trainer_availabilities.map((value, i) => {
            //     slots_arr.push(JSON.parse(value));
            // });
            // setSelectedSlots(slots_arr);
            setSelectedSlots(
                props.availability.data.trainer_slot.filter((x) => x.is_available == 1 || x.is_slot_booked == 1)
            );
        }
        setSkipRender(false);
    }, [props.availability]);

    return (
        <>
            <div className="schedule-availability-sec">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="schedule-avilability-header-sec">
                                <h3 class="main-title">Personalized Scheduling</h3>
                                <p>NOTE: People can only book time with you during the slots you specify</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="schedule-availability-box">
                        <Row>
                            <Col xl={8} md={12} className="resp-marg-btm-xl resp-marg-btm-xs">
                                <div className="schedule-availability-calendar-box">
                                    <CalenderScheduler
                                        date={today}
                                        format="YYYY-MM-DD"
                                        disablePastDate={true}
                                        onChange={startTimeOnchange}
                                        onGoogleClick={syncGoogleCalendar}
                                    />
                                </div>
                            </Col>
                            <Col xl={4} md={12} className="pl-0 resp-left-xs">
                                <div className="schedule-availability-select-box">
                                    <div className="schedule-availability-select-header-sec">
                                        <h3 className="mb-2">Schedule Availability</h3>
                                        <p>{props.profile.data.time_formatted}</p>
                                    </div>
                                    <div className="schedule-availability-select-card">
                                        <div className="schedule-availability-select-top-sec">
                                            <h4>{startTimeFormatted}</h4>
                                            <div className="schedule-availability-select-all-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="todo"
                                                    name="todo"
                                                    value="todo"
                                                    checked={props.availability.data.trainer_slot?.length === selectedSlots.length}
                                                    onClick={() => {
                                                        if (props.availability.data.trainer_slot?.length === selectedSlots.length)
                                                            unselectAllTimeSlots()
                                                        else
                                                            selectAllTimeSlots()
                                                    }}
                                                />
                                                <label for="todo" data-content="Get out of bed">
                                                    Mark All Available
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            className="schedule-availability-select-middle-sec"
                                            style={{
                                                height: "calc(100vh - 220px)",
                                                overflowY: "auto",
                                                marginTop: "1em",
                                            }}
                                        >
                                            {props.availability.loading ? (
                                                <CalenderLoader />
                                            ) : (
                                                <ul className="list-unstyled schedule-availability-select-list">
                                                    {props.availability.data.trainer_slot.length > 0
                                                        ? props.availability.data.trainer_slot.map(
                                                            (trainer_slot, index) => (
                                                                <li
                                                                    className={
                                                                        trainer_slot.is_slot_booked
                                                                            ? "booked-status"
                                                                            : trainer_slot.is_available
                                                                                ? ""
                                                                                : "not-available-status"
                                                                    }
                                                                >
                                                                    <label
                                                                        for={"radio-card-" + index}
                                                                        className="radio-card"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            name={"radio-" + index}
                                                                            id={"radio-card-" + index}
                                                                            onChange={(e) =>
                                                                                onClickTimeslot(trainer_slot)
                                                                            }
                                                                            disabled={trainer_slot.is_slot_booked}
                                                                            checked={selectedSlots.includes(
                                                                                trainer_slot
                                                                            )}
                                                                        // defaultChecked={trainer_slot.is_slot_booked ? true : (trainer_slot.is_available ? true : false)}
                                                                        />
                                                                        <div className="card-content-wrapper">
                                                                            <div className="schedule-availability-time">
                                                                                <span>
                                                                                    {trainer_slot.start_time}
                                                                                </span>
                                                                                <span>-</span>
                                                                                <span>{trainer_slot.end_time}</span>
                                                                            </div>
                                                                            <div className="schedule-availability-status-tick">
                                                                                <span className="check-icon"></span>
                                                                                <h4>
                                                                                    {
                                                                                        trainer_slot.is_slot_booked
                                                                                            ? "Booked"
                                                                                            : props.availability.data
                                                                                                .is_new_slot
                                                                                                ? "Available"
                                                                                                : trainer_slot.is_available
                                                                                                    ? "Available"
                                                                                                    : "Not Available"}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </li>
                                                            )
                                                        )
                                                        : null}
                                                    {/* <li
                                                        className="not-available-status"
                                                    >
                                                        <label
                                                            for="radio-card-1"
                                                            className="radio-card"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id="radio-card-1"
                                                            />
                                                            <div className="card-content-wrapper">
                                                                <div className="schedule-availability-time">
                                                                    <span>
                                                                        07:00 AM
                                                                    </span>
                                                                    <span>-</span>
                                                                    <span>08:00 AM</span>
                                                                </div>
                                                                <div className="schedule-availability-status-tick">
                                                                    <span className="check-icon"></span>
                                                                    <h4>
                                                                        Available
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                    <li
                                                        className="booked-status"
                                                    >
                                                        <label
                                                            for="radio-card-2"
                                                            className="radio-card"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id="radio-card-2"
                                                            />
                                                            <div className="card-content-wrapper">
                                                                <div className="schedule-availability-time">
                                                                    <span>
                                                                        07:00 AM
                                                                    </span>
                                                                    <span>-</span>
                                                                    <span>08:00 AM</span>
                                                                </div>
                                                                <div className="schedule-availability-status-tick">
                                                                    <span className="check-icon"></span>
                                                                    <h4>
                                                                        Booked
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                    <li
                                                        className="not-available-status"
                                                    >
                                                        <label
                                                            for="radio-card-3"
                                                            className="radio-card"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id="radio-card-3"
                                                            />
                                                            <div className="card-content-wrapper">
                                                                <div className="schedule-availability-time">
                                                                    <span>
                                                                        07:00 AM
                                                                    </span>
                                                                    <span>-</span>
                                                                    <span>08:00 AM</span>
                                                                </div>
                                                                <div className="schedule-availability-status-tick">
                                                                    <span className="check-icon"></span>
                                                                    <h4>
                                                                        Available
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                    <li
                                                        className="not-available-status"
                                                    >
                                                        <label
                                                            for="radio-card-4"
                                                            className="radio-card"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id="radio-card-4"
                                                            />
                                                            <div className="card-content-wrapper">
                                                                <div className="schedule-availability-time">
                                                                    <span>
                                                                        07:00 AM
                                                                    </span>
                                                                    <span>-</span>
                                                                    <span>08:00 AM</span>
                                                                </div>
                                                                <div className="schedule-availability-status-tick">
                                                                    <span className="check-icon"></span>
                                                                    <h4>
                                                                        Not Available
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                    <li
                                                        className="not-available-status"
                                                    >
                                                        <label
                                                            for="radio-card-5"
                                                            className="radio-card"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id="radio-card-5"
                                                            />
                                                            <div className="card-content-wrapper">
                                                                <div className="schedule-availability-time">
                                                                    <span>
                                                                        07:00 AM
                                                                    </span>
                                                                    <span>-</span>
                                                                    <span>08:00 AM</span>
                                                                </div>
                                                                <div className="schedule-availability-status-tick">
                                                                    <span className="check-icon"></span>
                                                                    <h4>
                                                                        Available
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li> */}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="schedule-availability-select-bottom-sec">
                                            <div className="schedule-availability-select-btn-sec">
                                                <Button
                                                    className="about-me-btn"
                                                    onClick={handleSubmit}
                                                    disabled={props.saveAvailability.buttonDisable}
                                                >
                                                    {props.saveAvailability.loadingButtonContent !==
                                                        null
                                                        ? props.saveAvailability.loadingButtonContent
                                                        : t("submit")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    availability: state.mentorAvailability.availability,
    saveAvailability: state.mentorAvailability.saveAvailability,
    profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(ScheduleAvailabilityIndex));