import {
    SEARCH_HISTORIES_LIST_START,
    SEARCH_HISTORIES_LIST_SUCCESS,
    SEARCH_HISTORIES_LIST_FAILURE,
    SEARCH_HISTORIES_DELETE_START,
    SEARCH_HISTORIES_DELETE_SUCCESS,
    SEARCH_HISTORIES_DELETE_FAILURE,
    SET_LOCAL_HISTORIES_LIST,
}
    from "./ActionConstant";

export function searchHistoriesListStart(data) {
    return {
        type: SEARCH_HISTORIES_LIST_START,
        data,
    };
}

export function searchHistoriesListSuccess(data) {
    return {
        type: SEARCH_HISTORIES_LIST_SUCCESS,
        data,
    };
}

export function searchHistoriesListFailure(error) {
    return {
        type: SEARCH_HISTORIES_LIST_FAILURE,
        error,
    };
}

export function setLocalHistoriesList(data) {
    return {
        type: SET_LOCAL_HISTORIES_LIST,
        data,
    }
}

export function searchHistoriesDeleteStart(data) {
    return {
        type: SEARCH_HISTORIES_DELETE_START,
        data,
    };
}

export function searchHistoriesDeleteSuccess(data) {
    return {
        type: SEARCH_HISTORIES_DELETE_SUCCESS,
        data,
    };
}

export function searchHistoriesDeleteFailure(error) {
    return {
        type: SEARCH_HISTORIES_DELETE_FAILURE,
        error,
    };
}