import {
    SEARCH_HISTORIES_SAVE_START,
    SEARCH_HISTORIES_SAVE_SUCCESS,
    SEARCH_HISTORIES_SAVE_FAILURE,
    SEARCH_HISTORIES_LIST_START,
    SEARCH_HISTORIES_LIST_SUCCESS,
    SEARCH_HISTORIES_LIST_FAILURE,
    SEARCH_HISTORIES_DELETE_START,
    SEARCH_HISTORIES_DELETE_SUCCESS,
    SEARCH_HISTORIES_DELETE_FAILURE,
    SET_LOCAL_HISTORIES_LIST,
} from "../actions/ActionConstant";

const initialState = {
    historyLists: {
        data: {},
        loading: true,
        error: false,
    },
    localHistories: [],
    deleteHistory: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
};

const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_HISTORIES_LIST_START:
            return {
                ...state,
                historyLists: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case SEARCH_HISTORIES_LIST_SUCCESS:
            return {
                ...state,
                historyLists: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case SEARCH_HISTORIES_LIST_FAILURE:
            return {
                ...state,
                historyLists: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case SET_LOCAL_HISTORIES_LIST:
            return {
                ...state,
                localHistories: action.data,
            }
        case SEARCH_HISTORIES_DELETE_START:
            return {
                ...state,
                deleteHistory: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case SEARCH_HISTORIES_DELETE_SUCCESS:
            return {
                ...state,
                deleteHistory: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case SEARCH_HISTORIES_DELETE_FAILURE:
            return {
                ...state,
                deleteHistory: {
                    data: {},
                    loading: false,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        default:
            return state;
    }
};

export default SearchReducer;