import {
  FETCH_VIDEOCALL_MODEL_START,
  FETCH_VIDEOCALL_MODEL_SUCCESS,
  FETCH_VIDEOCALL_MODEL_FAILURE,
  GET_VIDEOCALL_MODEL_START,
  GET_VIDEOCALL_MODEL_SUCCESS,
  GET_VIDEOCALL_MODEL_FAILURE,
  STATUS_VIDEOCALL_MODEL_START,
  STATUS_VIDEOCALL_MODEL_SUCCESS,
  STATUS_VIDEOCALL_MODEL_FAILURE,
  SEND_VIDEOCALL_USER_START,
  SEND_VIDEOCALL_USER_SUCCESS,
  SEND_VIDEOCALL_USER_FAILURE,
  FETCH_VIDEOCALL_USER_START,
  FETCH_VIDEOCALL_USER_SUCCESS,
  FETCH_VIDEOCALL_USER_FAILURE,
  MODIFY_VIDEOCALL_USER_SUCCESS,
  GET_VIDEOCALL_USER_START,
  GET_VIDEOCALL_USER_SUCCESS,
  GET_VIDEOCALL_USER_FAILURE,
  STATUS_VIDEOCALL_USER_START,
  STATUS_VIDEOCALL_USER_SUCCESS,
  STATUS_VIDEOCALL_USER_FAILURE,
  JOIN_VIDEOCALL_START,
  JOIN_VIDEOCALL_SUCCESS,
  JOIN_VIDEOCALL_FAILURE,
  END_VIDEOCALL_START,
  END_VIDEOCALL_SUCCESS,
  END_VIDEOCALL_FAILURE,
  FETCH_MORE_VIDEOCALL_USER_START,
  FETCH_MORE_VIDEOCALL_MODEL_START,
  MODIFY_VIDEOCALL_MODEL_SUCCESS,
  VIDEO_CALL_PAYMENT_BY_STRIPE_START,
  VIDEO_CALL_PAYMENT_BY_STRIPE_SUCCESS,
  VIDEO_CALL_PAYMENT_BY_STRIPE_FAILURE,
  FETCH_VIDEO_CALL_VIEW_START,
  FETCH_VIDEO_CALL_VIEW_SUCCESS,
  FETCH_VIDEO_CALL_VIEW_FAILURE,
  FETCH_EVENT_MODEL_LIST_START,
  FETCH_EVENT_MODEL_LIST_SUCCESS,
  FETCH_EVENT_MODEL_LIST_FAILURE,
  FETCH_MORE_EVENT_MODEL_LIST_START,
  START_VIDEOCALL_START,
  START_VIDEOCALL_SUCCESS,
  START_VIDEOCALL_FAILURE,
} from "./ActionConstant";


// model video call index
export function fetchVideocallModelStart(data) {
  return {
    type: FETCH_VIDEOCALL_MODEL_START,
    data,
  };
}

export function fetchVideocallModelSuccess(data) {
  return {
    type: FETCH_VIDEOCALL_MODEL_SUCCESS,
    data,
  };
}

export function fetchVideocallModelFailure(error) {
  return {
    type: FETCH_VIDEOCALL_MODEL_FAILURE,
    error,
  };
}

export function fetchMoreVideocallModelStart(data) {
  return {
    type: FETCH_MORE_VIDEOCALL_MODEL_START,
    data,
  }
}

export function modifyVideocallModelSuccess(data) {
  return {
    type: MODIFY_VIDEOCALL_MODEL_SUCCESS,
    data,
  }
}


// model video call view 
export function getVideocallModelStart(data) {
  return {
    type: GET_VIDEOCALL_MODEL_START,
    data,
  };
}

export function getVideocallModelSuccess(data) {
  return {
    type: GET_VIDEOCALL_MODEL_SUCCESS,
    data,
  };
}

export function getVideocallModelFailure(error) {
  return {
    type: GET_VIDEOCALL_MODEL_FAILURE,
    error,
  };
}

// model video call status
export function statusVideocallModelStart(data) {
  return {
    type: STATUS_VIDEOCALL_MODEL_START,
    data,
  };
}

export function statusVideocallModelSuccess(data) {
  return {
    type: STATUS_VIDEOCALL_MODEL_SUCCESS,
    data,
  };
}

export function statusVideocallModelFailure(error) {
  return {
    type: STATUS_VIDEOCALL_MODEL_FAILURE,
    error,
  };
}

// user video call send 
export function sendVideocallUserStart(data) {
  return {
    type: SEND_VIDEOCALL_USER_START,
    data,
  };
}

export function sendVideocallUserSuccess(data) {
  return {
    type: SEND_VIDEOCALL_USER_SUCCESS,
    data,
  };
}

export function sendVideocallUserFailure(error) {
  return {
    type: SEND_VIDEOCALL_USER_FAILURE,
    error,
  };
}

// user video call index
export function fetchVideocallUserStart(data) {
  return {
    type: FETCH_VIDEOCALL_USER_START,
    data,
  };
}

export function fetchVideocallUserSuccess(data) {
  return {
    type: FETCH_VIDEOCALL_USER_SUCCESS,
    data,
  };
}

export function fetchVideocallUserFailure(error) {
  return {
    type: FETCH_VIDEOCALL_USER_FAILURE,
    error,
  };
}

export function fetchMoreVideocallUserStart(data) {
  return {
    type: FETCH_MORE_VIDEOCALL_USER_START,
    data,
  }
}

export function modifyVideocallUserSuccess(data) {
  return {
    type: MODIFY_VIDEOCALL_USER_SUCCESS,
    data,
  }
}

// user video call view 
export function getVideocallUserStart(data) {
  return {
    type: GET_VIDEOCALL_USER_START,
    data,
  };
}

export function getVideocallUserSuccess(data) {
  return {
    type: GET_VIDEOCALL_USER_SUCCESS,
    data,
  };
}

export function getVideocallUserFailure(error) {
  return {
    type: GET_VIDEOCALL_USER_FAILURE,
    error,
  };
}

// user video call status
export function statusVideocallUserStart(data) {
  return {
    type: STATUS_VIDEOCALL_USER_START,
    data,
  };
}

export function statusVideocallUserSuccess(data) {
  return {
    type: STATUS_VIDEOCALL_USER_SUCCESS,
    data,
  };
}

export function statusVideocallUserFailure(error) {
  return {
    type: STATUS_VIDEOCALL_USER_FAILURE,
    error,
  };
}

// VIDOE CALL BOTH
export function joinVideocallStart(data) {
  return {
    type: JOIN_VIDEOCALL_START,
    data,
  };
}

export function joinVideocallSuccess(data) {
  return {
    type: JOIN_VIDEOCALL_SUCCESS,
    data,
  };
}

export function joinVideocallFailure(error) {
  return {
    type: JOIN_VIDEOCALL_FAILURE,
    error,
  };
}

export function endVideocallStart(data) {
  return {
    type: END_VIDEOCALL_START,
    data,
  };
}

export function endVideocallSuccess(data) {
  return {
    type: END_VIDEOCALL_SUCCESS,
    data,
  };
}

export function endVideocallFailure(error) {
  return {
    type: END_VIDEOCALL_FAILURE,
    error,
  };
}

export function videoCallPaymentByStripeStart(data) {
  return {
    type: VIDEO_CALL_PAYMENT_BY_STRIPE_START,
    data,
  };
}

export function videoCallPaymentByStripeSuccess(data) {
  return {
    type: VIDEO_CALL_PAYMENT_BY_STRIPE_SUCCESS,
    data,
  };
}

export function videoCallPaymentByStripeFailure(error) {
  return {
    type: VIDEO_CALL_PAYMENT_BY_STRIPE_FAILURE,
    error,
  };
}

export function fetchVideoCallViewStart(data) {
  return {
    type: FETCH_VIDEO_CALL_VIEW_START,
    data,
  };
}

export function fetchVideoCallViewSuccess(data) {
  return {
    type: FETCH_VIDEO_CALL_VIEW_SUCCESS,
    data,
  };
}

export function fetchVideoCallViewFailure(error) {
  return {
    type: FETCH_VIDEO_CALL_VIEW_FAILURE,
    error,
  }
}

export function fetchEventModelListStart(data) {
  return {
    type: FETCH_EVENT_MODEL_LIST_START,
    data,
  };
}

export function fetchEventModelListSuccess(data) {
  return {
    type: FETCH_EVENT_MODEL_LIST_SUCCESS,
    data,
  };
}

export function fetchEventModelListFailure(error) {
  return {
    type: FETCH_EVENT_MODEL_LIST_FAILURE,
    error,
  };
}

export function fetchMoreEventModelListStart(data) {
  return {
    type: FETCH_MORE_EVENT_MODEL_LIST_START,
    data,
  }
}


export function startVideocallStart(data) {
  return {
    type: START_VIDEOCALL_START,
    data,
  };
}

export function startVideocallSuccess(data) {
  return {
    type: START_VIDEOCALL_SUCCESS,
    data,
  };
}

export function startVideocallFailure(error) {
  return {
    type: START_VIDEOCALL_FAILURE,
    error,
  };
}