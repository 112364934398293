import React, { useState, useEffect } from "react";
// import HeaderIndex from "./Header/HeaderIndex";
import AuthHeaderIndex from "./Header/AuthHeaderIndex";
import { Notify } from "react-redux-notify";
import { connect } from "react-redux";
import FooterIndex from "./Footer/FooterIndex";
import { useHistory } from "react-router";
import { t, withTranslation } from "react-multi-lang";
import { getKycDocumentStart } from "../../store/actions/KycDocumentAction";
import { fetchSubscriptionStart } from "../../store/actions/SubscriptionAction";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

const MainLayout = (props) => {
  const history = useHistory();
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken") && localStorage.getItem("is_model") == 2) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, []);


  useEffect(() => {
    if (!skipRender && localStorage.getItem("userId") && localStorage.getItem("accessToken") && localStorage.getItem("is_model") == 2) {
      if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
        props.dispatch(getKycDocumentStart());
        props.dispatch(fetchSubscriptionStart());
      }
    }
    setSkipRender(false);
  }, [props.profile]);

  useEffect(() => {
    if (localStorage.getItem("is_model") == 2) {
      if (!skipRender && !props.kycDocDetails.loading && Object.keys(props.kycDocDetails.data).length > 0) {
        if (props.kycDocDetails.data.is_document_verified === 2) {
          if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
            if (props.profile.data.category_name === "") {
              history.push("/categories");
            } else if (!props.subscription.loading && Object.keys(props.subscription.data).length > 0) {
              if (props.subscription.data.total_subscriptions === 0) {
                history.push("/create-subscription");
              }
            }
          }
        }
        else if (props.kycDocDetails.data.is_document_verified != 2) {
          history.push("/kyc-documents");
        }
      }

    }
  }, [props.kycDocDetails, props.profile, props.subscription]);


  return (
    <div className="">
      <Notify position="TopRight" />
      {/* <HeaderIndex /> */}
      <AuthHeaderIndex />
      <div className="main-wrapper">
        {React.cloneElement(props.children)}
      </div>
      <FooterIndex />
    </div>
  );

}
const mapStateToPros = (state) => ({
  kycDocDetails: state.kycDocument.kycDocDetails,
  subscription: state.subscriptions.subscription,
  profile: state.users.profile,

});
function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(MainLayout));



