import {
    FETCH_MENTORS_AVAILABILITY_START,
    FETCH_MENTORS_AVAILABILITY_SUCCESS,
    FETCH_MENTORS_AVAILABILITY_FAILURE,
    FETCH_MENTORS_AVAILABILITY_USER_START,
    FETCH_MENTORS_AVAILABILITY_USER_SUCCESS,
    FETCH_MENTORS_AVAILABILITY_USER_FAILURE,
    DELETE_MENTORS_AVAILABILITY_START,
    DELETE_MENTORS_AVAILABILITY_SUCCESS,
    DELETE_MENTORS_AVAILABILITY_FAILURE,
    SAVE_MENTORS_AVAILABILITY_START,
    SAVE_MENTORS_AVAILABILITY_SUCCESS,
    SAVE_MENTORS_AVAILABILITY_FAILURE,
    SYNC_GOOGLE_CALENDAR_EVENTS_START,
    SYNC_GOOGLE_CALENDAR_EVENTS_SUCCESS,
    SYNC_GOOGLE_CALENDAR_EVENTS_FAILURE,
    SYNC_OUTLOOK_CALENDAR_EVENTS_START,
    SYNC_OUTLOOK_CALENDAR_EVENTS_SUCCESS,
    SYNC_OUTLOOK_CALENDAR_EVENTS_FAILURE,
} from '../actions/ActionConstant'

const initialState = {
    availability: {
        data: {},
        loading: true,
        error: false,
    },
    mentorAvailability: {
        data: {},
        loading: true,
        error: false,
    },
    saveAvailability: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
    availabilityDelete: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
    syncGoogleCalendar: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
    syncOutlookCalendar: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
}

const AvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MENTORS_AVAILABILITY_START:
            return {
                ...state,
                availability: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_MENTORS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availability: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_MENTORS_AVAILABILITY_FAILURE:
            return {
                ...state,
                availability: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case FETCH_MENTORS_AVAILABILITY_USER_START:
            return {
                ...state,
                mentorAvailability: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_MENTORS_AVAILABILITY_USER_SUCCESS:
            return {
                ...state,
                mentorAvailability: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_MENTORS_AVAILABILITY_USER_FAILURE:
            return {
                ...state,
                mentorAvailability: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case SAVE_MENTORS_AVAILABILITY_START:
            return {
                ...state,
                saveAvailability: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case SAVE_MENTORS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                saveAvailability: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case SAVE_MENTORS_AVAILABILITY_FAILURE:
            return {
                ...state,
                saveAvailability: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case DELETE_MENTORS_AVAILABILITY_START:
            return {
                ...state,
                availabilityDelete: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case DELETE_MENTORS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availabilityDelete: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case DELETE_MENTORS_AVAILABILITY_FAILURE:
            return {
                ...state,
                availabilityDelete: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };

        case SYNC_GOOGLE_CALENDAR_EVENTS_START:
            return {
                ...state,
                syncGoogleCalendar: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case SYNC_GOOGLE_CALENDAR_EVENTS_SUCCESS:
            return {
                ...state,
                syncGoogleCalendar: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case SYNC_GOOGLE_CALENDAR_EVENTS_FAILURE:
            return {
                ...state,
                syncGoogleCalendar: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case SYNC_OUTLOOK_CALENDAR_EVENTS_START:
            return {
                ...state,
                syncOutlookCalendar: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case SYNC_OUTLOOK_CALENDAR_EVENTS_SUCCESS:
            return {
                ...state,
                syncOutlookCalendar: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case SYNC_OUTLOOK_CALENDAR_EVENTS_FAILURE:
            return {
                ...state,
                syncOutlookCalendar: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        default:
            return state;
    }
}

export default AvailabilityReducer;