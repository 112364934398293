import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ClientReview = (props) => {

  return (
    <>
      <div className="client-review-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={9}>
                    <div className="section-title">
                        <h2>Raw and Real<span>Feedback</span></h2>
                        <p>OhHello kicks biased mentorship, imposter syndrome, and self-doubt to the curb. OhHello is providing access to experts at all stages and levels. Accessible, cherry-picked, mentorship for when you need REAL guidance.</p>
                    </div>
                </Col>
            </Row>
            <Row className="sm-margin-top justify-content-md-center">
               <Col md={6} xl={4} lg={4}>
                    <div className="client-review-card">
                        <div className="client-review-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/landing/client-img-1.png`}
                                className="client-review-img"
                            />
                        </div>
                        <div className="client-review-rating-sec">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>  
                        </div>
                        <div className="client-review-desc">
                            <p> Such a cool experience to have 2 direct convos about career ascension w/ people 5 and 15 yrs ahead of me in CS and Ops. Felt like 2 full MBA courses 🪄🎩 in, legit, 60 minutes!</p>
                            <h4>Jordan </h4>
                        </div>
                    </div>
               </Col>
               <Col md={6} xl={4} lg={4}>
                    <div className="client-review-card">
                        <div className="client-review-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/landing/client-img-2.png`}
                                className="client-review-img"
                            />
                        </div>
                        <div className="client-review-rating-sec">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>  
                        </div>
                        <div className="client-review-desc">
                            <p>Getting promoted from an SDR to a first time AE, I wanted to chat w/ some more experienced sellers. After chatting w/ a few 'Hellos', I'm confidently gonna SLAY even faster 🚀</p>
                            <h4>Julia</h4>
                        </div>
                    </div>
               </Col>
               <Col md={6} xl={4} lg={4}>
                    <div className="client-review-card">
                        <div className="client-review-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/landing/client-img-3.png`}
                                className="client-review-img"
                            />
                        </div>
                        <div className="client-review-rating-sec">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                >
                                <path d="M19.467 23.316L12 17.828l-7.467 5.488L7.4 14.453-.063 9h9.214L12 .122 14.849 9h9.213L16.6 14.453z"></path>
                            </svg>  
                        </div>
                        <div className="client-review-desc">
                            <p>If 25 y/o Shiv could go back in time, this concept would've been so immensely
                                 helpful for when I needed advice/quick no b.s. answers. I ❤️ what OhHello is doing for the industry!</p>
                            <h4>Shiv</h4>
                        </div>
                    </div>
               </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default ClientReview;
