import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import * as Styles from "./CalenderStyles";
import { Button } from "react-bootstrap";

export const CalenderScheduler = ({
  format = "YYYY-MM-DD",
  date,
  disablePastDate = false,
  onChange,
  onGoogleClick,
}) => {
  const today = date ? dayjs(date) : dayjs();

  const [selectedDate, setSelectedDate] = useState(today);
  const [calenderDate, setCalenderDate] = useState(today);

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const firstDayOfTheMonth = useMemo(
    () => calenderDate.clone().startOf("month"),
    [calenderDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    const dates = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day").toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [
    generateFirstDayOfEachWeek,
    firstDayOfFirstWeekOfMonth,
    generateWeek,
    calenderDate,
  ]);

  const onDateClick = (date) => {
    setCalenderDate(date);
    setSelectedDate(date);
    onChange(date.format(format));
  };

  return (
    <Styles.MainWrapper>
      <Styles.CalendarHeaderWrapper>
        <div className="calender-title">
          <MdKeyboardArrowLeft
            size={25}
            onClick={() => setCalenderDate((date) => date.subtract(1, "month"))}
          />
          <h3>{calenderDate.clone().format("MMM YYYY")}</h3>
          <MdKeyboardArrowRight
            size={25}
            onClick={() => setCalenderDate((date) => date.add(1, "month"))}
          />
        </div>
        <div className="sync-with-google-outlook-sec">
          <span>Sync with:</span>
          <button className="google-sync-btn" onClick={onGoogleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="31"
              fill="none"
              viewBox="0 0 30 31"
            >
              <g clipPath="url(#clip0_310_251)">
                <path
                  fill="#0085F7"
                  d="M10.344 19.854c-.59-.399-.998-.981-1.221-1.75l1.37-.565c.124.473.34.84.65 1.101.308.26.683.389 1.122.389.448 0 .833-.136 1.154-.409.322-.272.484-.62.484-1.04 0-.43-.17-.781-.51-1.054-.339-.272-.765-.408-1.274-.408h-.792v-1.356h.71c.439 0 .808-.119 1.108-.356.3-.236.45-.56.45-.973 0-.367-.134-.659-.403-.878-.268-.219-.608-.33-1.02-.33-.403 0-.722.107-.96.322-.236.216-.408.48-.516.792l-1.356-.565c.18-.509.509-.959.992-1.348.484-.389 1.102-.584 1.852-.584.554 0 1.054.107 1.496.322.442.215.79.513 1.04.892.25.38.375.807.375 1.28 0 .484-.117.893-.35 1.229-.232.335-.518.592-.858.771v.081c.448.188.813.474 1.101.859.287.385.43.845.43 1.381 0 .537-.135 1.017-.408 1.437a2.83 2.83 0 01-1.127.993c-.48.24-1.018.363-1.616.363a3.346 3.346 0 01-1.923-.596zM18.758 13.057l-1.504 1.087-.752-1.14 2.698-1.947h1.034v9.18h-1.476v-7.18z"
                ></path>
                <path
                  fill="#00A94B"
                  d="M22.895 23.395H7.105l-2.26 3.33 2.26 3.775h15.79l1.867-4.016-1.867-3.09z"
                ></path>
                <path
                  fill="#0085F7"
                  d="M22.895.5H2.368A2.368 2.368 0 000 2.868v20.527l3.553 2.525 3.552-2.525V7.605h15.79l2.43-3.552L22.895.5z"
                ></path>
                <path
                  fill="#00802E"
                  d="M0 23.395v4.737A2.368 2.368 0 002.368 30.5h4.737v-7.105H0z"
                ></path>
                <path
                  fill="#FFBC00"
                  d="M30 7.605L26.447 5.32l-3.552 2.286v15.79l3.195 1.668L30 23.395V7.605z"
                ></path>
                <path
                  fill="#0067D5"
                  d="M30 7.605V2.868A2.368 2.368 0 0027.63.5h-4.736v7.105H30z"
                ></path>
                <path fill="#FF4131" d="M22.895 30.5L30 23.395h-7.105V30.5z"></path>
              </g>
              <defs>
                <clipPath id="clip0_310_251">
                  <path
                    fill="#fff"
                    d="M0 0H30V30H0z"
                    transform="translate(0 .5)"
                  ></path>
                </clipPath>
              </defs>
            </svg>
          </button>
          <span>or</span>
          <button className="outlook-sync-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="33"
              fill="none"
              viewBox="0 0 34 33"
            >
              <g clipPath="url(#clip0_310_262)">
                <path
                  fill="#1976D2"
                  d="M21.048 7.464h11.765c.655 0 1.187.532 1.187 1.188v15.434c0 .656-.532 1.188-1.187 1.188H21.048V7.463z"
                ></path>
                <path fill="#fff" d="M32.381 11.478H21.048v12.581H32.38V11.478z"></path>
                <path
                  fill="#1976D2"
                  d="M20.239 32.56l-18.62-3.644V3.821L20.24.178V32.56z"
                ></path>
                <path
                  fill="#fff"
                  d="M10.726 10.297c-2.57 0-4.654 2.719-4.654 6.072s2.083 6.071 4.654 6.071c2.572 0 4.655-2.718 4.655-6.071 0-3.353-2.083-6.072-4.655-6.072zm-.202 9.715c-1.341 0-2.428-1.632-2.428-3.643 0-2.012 1.087-3.643 2.428-3.643 1.342 0 2.429 1.631 2.429 3.643 0 2.011-1.087 3.643-2.429 3.643z"
                ></path>
                <path
                  fill="#1976D2"
                  d="M23.272 21.013h-2.186v2.348h2.186v-2.348zM26.025 21.013H23.84v2.348h2.186v-2.348zM28.778 21.013h-2.186v2.348h2.186v-2.348zM23.272 18.116h-2.186v2.348h2.186v-2.348zM26.025 18.116H23.84v2.348h2.186v-2.348zM28.778 18.116h-2.186v2.348h2.186v-2.348zM31.531 18.116h-2.185v2.348h2.185v-2.348zM23.272 15.321h-2.186v2.348h2.186V15.32zM26.025 15.321H23.84v2.348h2.186V15.32zM28.778 15.321h-2.186v2.348h2.186V15.32zM31.531 15.321h-2.185v2.348h2.185V15.32zM26.025 12.412H23.84v2.347h2.186v-2.347zM28.778 12.412h-2.186v2.347h2.186v-2.347zM31.531 12.412h-2.185v2.347h2.185v-2.347z"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_310_262">
                  <path
                    fill="#fff"
                    d="M0 0H34V32.162H0z"
                    transform="translate(0 .419)"
                  ></path>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </Styles.CalendarHeaderWrapper>
      <Styles.WeekDaysWrapper>
        {generateWeeksOfTheMonth[0].map((day, index) => (
          <Styles.WeekDayCell key={`week-day-${index}`}>
            <Styles.WeekDayCellDesktop>
              {new Date(day).toLocaleDateString("en-us", { weekday: "long" })}
            </Styles.WeekDayCellDesktop>
            <Styles.WeekDayCellMobile>
              {dayjs(day).format("dd")}
            </Styles.WeekDayCellMobile>
          </Styles.WeekDayCell>
        ))}
      </Styles.WeekDaysWrapper>
      {generateWeeksOfTheMonth.map((week, weekIndex) => (
        <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
          {week.map((day, dayIndex) => (
            <Styles.CalendarDayCell
              key={`day-${dayIndex}`}
              onClick={() => {
                const date = dayjs(day);
                if (disablePastDate) {
                  if (!date.isBefore(dayjs(today.format(format), format)))
                    onDateClick(date);
                } else {
                  onDateClick(date);
                }
              }}
            >
              <Styles.CalendarDayInnerCell
                variant={
                  disablePastDate &&
                    dayjs(day).isBefore(dayjs(today.format(format), format))
                    ? "nextMonth"
                    : calenderDate.clone().toDate().getMonth() !==
                      day.getMonth()
                      ? "nextMonth"
                      : dayjs(selectedDate).isSame(day, "date")
                        ? "selected"
                        : dayjs(day).isSame(today.format(format), format)
                          ? "today"
                          : "default"
                }
              >
                <span>{day.getDate()}</span>
              </Styles.CalendarDayInnerCell>
            </Styles.CalendarDayCell>
          ))}
        </Styles.CalendarContentWrapper>
      ))}
    </Styles.MainWrapper>
  );
};
