import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchFeaturedMentorsListStart } from "../../store/actions/LookUpAction";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

const OurMentor = (props) => {
  const t = useTranslation("");

  useEffect(() => {
    props.dispatch(
      fetchFeaturedMentorsListStart({
        order_by: "newest",
        skip: 0,
        take: 3,
      })
    );
  }, []);

  return (
    <>
      {props.featuredMentors.loading ? (
        <div className="our-mentor-card">
          {[...Array(3)].map((val, i) => (
            <Skeleton height={445} key={i} />
          ))}
        </div>
      ) : Object.keys(props.featuredMentors.data).length > 0 &&
        props.featuredMentors.data.featured_mentors.length > 0 ? (
        <div className="our-mentor-sec sm-padding">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={9}>
                <div className="section-title">
                  <h2>
                    {t("vulnerability_is_a_strength")}
                    <span>{t("hand_pick_your_mentors")}</span>
                  </h2>
                  <p>{t("feeling_lost_para")}</p>
                </div>
              </Col>
            </Row>
            <Row className="sm-margin-top justify-content-md-center">
              {props.featuredMentors.data.featured_mentors.map(
                (mentor) =>
                  mentor.user && (
                    <Col md={6} xl={4} lg={4}>
                      <Link to={`/${mentor.user.username}`}>
                        <div className="our-mentor-card">
                          <Image
                            src={mentor.user.picture}
                            alt={mentor.user.username}
                            className="our-mentor-img"
                          />
                          <div className="our-mentor-info">
                            <h4>{mentor.user.username}</h4>
                            <p>{mentor.user.about}</p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )
              )}
            </Row>
          </Container>
        </div>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  featuredMentors: state.lookup.featuredMentorsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OurMentor);
