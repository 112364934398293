import React from "react";
import { Form, Modal, Col, Row, Button, Image } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { connect } from "react-redux";
import { t, withTranslation } from "react-multi-lang";

const VideoCallDetailsModal = (props) => {
  const { videoCall } = props;

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("ticket_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  return (
    <Modal
      className="modal-dialog-center payment-add-card-modal join-video-call-modal"
      size="md"
      centered
      show={true}
      onHide={props.closeVideoCallDetailsModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("video_call_details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <img
            src={
              props.isSent ? videoCall.model.picture : videoCall.user.picture
            }
            className="table-user-image"
          />
          <Col sm={12} className="text-center text-white">
            {props.isSent ? videoCall.model.name : videoCall.user.name}
          </Col>
          <hr />
          <Col>
            <Row className="mt-3">
              <Col sm={6} className="text-left text-white">
                {t("scheduled_date")}
              </Col>
              <Col sm={6} className="text-left text-white">
                {videoCall.schedule_time_formatted}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={6} className="text-left text-white">
                {t("timezone")}
              </Col>
              <Col sm={6} className="text-left text-white">
                {videoCall.time_formatted}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={6} className="text-left text-white">
                {t("ticket")}
              </Col>
              <Col sm={6} className="text-left text-white">
                <div className="ticket-coupon-card">
                  {videoCall.ticket}
                  <span>
                    <CopyToClipboard text={videoCall.ticket} onCopy={onCopy}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/live-events/copy.svg"
                        }
                        alt="copy-icon"
                        className="copy-icon"
                      />
                    </CopyToClipboard>
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={6} className="text-left text-white">
                {t("status")}
              </Col>
              <Col sm={6} className={
                  {
                    0: "text-warning",
                    6: "text-success",
                    5: "text-danger",
                    7: "text-success",
                    8: "text-white",
                  }[videoCall.status]
                } >
								{videoCall.status_formatted}
							</Col>
            </Row>
            <Row className="mt-3">
              <Col sm={6} className="text-left text-white">
                Description
              </Col>
              <Col sm={6} className="text-white">
                {videoCall.message ? videoCall.message : t("na")}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation(VideoCallDetailsModal));
