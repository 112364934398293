import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";
import ProfileLoader from "../Handlers/Loaders/ProfileLoader";


import "./Account.css";
import { fetchUserDetailsStart, saveCalendarDetailsStart } from "../../store/actions/UserAction";


const SyncCalendar = (props) => {
    const [inputData, setInputData] = useState({});


    useEffect(() => {
        if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(saveCalendarDetailsStart(inputData));
    };

    return (
        <>
            {props.profile.loading ? (
                <ProfileLoader></ProfileLoader>
            ) : (
                <div className="main-wrapper sync-calc">
                    <div className="change-password-sec">
                        <div className="change-password-card">
                            <h3 className="title">{t("sync_calendar")}</h3>
                            <Form className="change-password-form" onSubmit={handleSubmit}>
                                <div key={`custom-inline`} className="mb-3">
                                <Form.Check
                                    type="radio"
                                    inline
                                    id="google"
                                    label={t("Google")}
                                    name="calendar"
                                    value="google"
                                    onChange={(event) =>
                                        setInputData({
                                            ...inputData,
                                            calendar: event.currentTarget.value,
                                        })
                                    }
                                    defaultChecked={
                                        props.profile.data.calendar === "google" ? true : false
                                    }
                                    custom
                                />
                                <Form.Check
                                    type="radio"
                                    inline
                                    id="outlook"
                                    label={t("Outlook")}
                                    name="calendar"
                                    value="outlook"
                                    onChange={(event) =>
                                        setInputData({
                                            ...inputData,
                                            calendar: event.currentTarget.value,
                                        })
                                    }
                                    defaultChecked={
                                        props.profile.data.calendar === "outlook" ? true : false
                                    }
                                    custom
                                />
                                </div>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t("google_client_id")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t("google_client_id")}
                                        name="google_client_id"
                                        defaultValue={props.profile.data.google_client_id}
                                        onChange={(event) =>
                                            setInputData({
                                                ...inputData,
                                                google_client_id: event.currentTarget.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t("google_api_key")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t("google_api_key")}
                                        name="google_api_key"
                                        defaultValue={props.profile.data.google_api_key}
                                        onChange={(event) =>
                                            setInputData({
                                                ...inputData,
                                                google_api_key: event.currentTarget.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                                <Button
                                    className="btn-change-password"
                                    disabled={props.calendar.buttonDisable}
                                    onClick={handleSubmit}
                                >
                                    {props.calendar.loadingButtonContent != null
                                        ? props.calendar.loadingButtonContent
                                        : t("update")}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToPros = (state) => ({
    profile: state.users.profile,
    calendar: state.users.calendarData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SyncCalendar));
