import React, { useState } from "react";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import ClientReview from "./ClientReview";
import HowItsWorking from "./HowItsWorking";
import LandingCTA from "./LandingCTA";
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";
import LandingHomeBanner from "./LandingHomeBanner";
import OurMentor from "./OurMentor";
import TopGlobalCompany from "./TopGlobalCompany";

const LandingPageIndex = (props) => {

  return (
    <>
      <div className="landing-page-sec">
        <div className="home-banner-sec">
          <LandingHeader />
          <LandingHomeBanner />
        </div>
        <TopGlobalCompany />
        <HowItsWorking />
        <OurMentor />
        <LandingCTA />
        <ClientReview />
        <LandingFooter />
      </div>
    </>
  );
};

export default LandingPageIndex;

