import {
  BOOKING_FILE_UPLOAD_START,
  BOOKING_FILE_UPLOAD_SUCCESS,
  BOOKING_FILE_UPLOAD_FAILURE,
  BOOKING_SAVE_START,
  BOOKING_SAVE_SUCCESS,
  BOOKING_SAVE_FAILURE,
  BOOKING_CANCEL_START,
  BOOKING_CANCEL_SUCCESS,
  BOOKING_CANCEL_FAILURE,
  FETCH_BOOKINGS_START,
  FETCH_MORE_BOOKING_START,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILURE,
  FETCH_SINGLE_BOOKING_START,
  FETCH_SINGLE_BOOKING_SUCCESS,
  FETCH_SINGLE_BOOKING_FAILURE,
  UPDATE_USER_REVIEW_START,
  UPDATE_USER_REVIEW_SUCCESS,
  UPDATE_USER_REVIEW_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  uploading: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  saveBooking: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  bookingCancel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  singleBooking: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  bookings: {
    data: {},
    loading: true,
    error: false,
  },
  postReviews: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
};

const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_FILE_UPLOAD_START:
      return {
        ...state,
        uploading: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case BOOKING_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case BOOKING_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        uploading: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case BOOKING_SAVE_START:
      return {
        ...state,
        saveBooking: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case BOOKING_SAVE_SUCCESS:
      return {
        ...state,
        saveBooking: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case BOOKING_SAVE_FAILURE:
      return {
        ...state,
        saveBooking: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case BOOKING_CANCEL_START:
      return {
        ...state,
        bookingCancel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case BOOKING_CANCEL_SUCCESS:
      return {
        ...state,
        bookingCancel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case BOOKING_CANCEL_FAILURE:
      return {
        ...state,
        bookingCancel: {
          data: {},
          loading: true,
          error: action.data,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case FETCH_BOOKINGS_START:
      return {
        ...state,
        bookings: {
          data: {
            bookings: [],
            total: 0,
          },
          loading: true,
          error: false,
          errorCount: 0,
        },
      };
      case FETCH_MORE_BOOKING_START:
        return state;
    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: {
            data: {
            bookings: [...state.bookings.data.bookings, ...action.data.bookings],
            total: action.data.total,
          },
          loading: false,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_BOOKINGS_FAILURE:
      return {
        ...state,
        bookings: {
          data: state.bookings.data,
          loading: true,
          error: action.error,
          errorCount: state.bookings.errorCount + 1,
        },
      };
    case FETCH_SINGLE_BOOKING_START:
      return {
        ...state,
        singleBooking: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case FETCH_SINGLE_BOOKING_SUCCESS:
      return {
        ...state,
        singleBooking: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SINGLE_BOOKING_FAILURE:
      return {
        ...state,
        singleBooking: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
      case UPDATE_USER_REVIEW_START:
        return {
          ...state,
          postReviews: {
            data: {},
            loading: true,
            error: false,
            inputData: action.data,
            loadingButtonContent: "Loading... Please wait.",
            buttonDisable: true,
          },
        };
      case UPDATE_USER_REVIEW_SUCCESS:
        return {
          ...state,
          postReviews: {
            data: action.data,
            loading: false,
            error: false,
            inputData: {},
            loadingButtonContent: null,
            buttonDisable: false,
          },
        };
      case UPDATE_USER_REVIEW_FAILURE:
        return {
          ...state,
          postReviews: {
            data: {},
            loading: true,
            error: action.error,
            inputData: {},
            loadingButtonContent: null,
            buttonDisable: false,
          },
        };
    default:
      return state;
  }
};

export default BookingReducer;
