import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  fetchCategoryFailure,
  fetchCategorySuccess,
  fetchOccasionSuccess,
  fetchSubCategoryFailure,
  fetchSubCategorySuccess,
  fetchOccasionFailure,
  fetchBannersSuccess,
  fetchBannersFailure,
  fetchFeaturedMentorsListSuccess,
  fetchFeaturedMentorsListFailure,
} from "../actions/LookUpAction";
import {
  FETCH_BANNERS_START,
  FETCH_CATEGORY_START,
  FETCH_OCCASION_START,
  FETCH_SUB_CATEGORY_START,
  FETCH_FEATURED_MENTORS_LIST_START,
} from "../actions/ActionConstant";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* fetchCategoryAPI() {
  try {
    const response = yield api.postMethod("categories_index");
    if (response.data.success) {
      yield put(fetchCategorySuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchCategoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCategoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSubCategoryAPI() {
  try {
    const inputData = yield select((state) => state.lookup.subCat.inputData);
    const response = yield api.postMethod("sub_categories_index", inputData);
    if (response.data.success) {
      yield put(fetchSubCategorySuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchSubCategoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSubCategoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchOccasionAPI() {
  try {
    const response = yield api.postMethod("occasions_index");
    if (response.data.success) {
      yield put(fetchOccasionSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchOccasionFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOccasionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBannersAPI() {
  try {
    const response = yield api.postMethod("banner_images");
    if (response.data.success) {
      yield put(fetchBannersSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchBannersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBannersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchFeaturedMentorsListAPI() {
  try {
    const inputData = yield select(
      (state) => state.lookup.featuredMentorsList.inputData);

    const response = yield api.postMethod("featured_mentors_list", inputData);
    if (response.data.success) {
      yield put(fetchFeaturedMentorsListSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchFeaturedMentorsListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchFeaturedMentorsListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_CATEGORY_START, fetchCategoryAPI)]);
  yield all([yield takeLatest(FETCH_SUB_CATEGORY_START, fetchSubCategoryAPI)]);
  yield all([yield takeLatest(FETCH_OCCASION_START, fetchOccasionAPI)]);
  yield all([yield takeLatest(FETCH_BANNERS_START, fetchBannersAPI)]);
  yield all([yield takeLatest(FETCH_FEATURED_MENTORS_LIST_START, fetchFeaturedMentorsListAPI)]);
}
