import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const TopGlobalCompany = (props) => {

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        // centerPadding: "60px",
        slidesToShow: 5.4,
        // speed: 500,
        rows: 2,
        slidesPerRow: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesPerRow: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1.5,
                slidesPerRow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1.5,
                slidesPerRow: 1,
              }
            }
          ]
      };

  return (
    <>
      <div className="top-global-company-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={9}>
                    <div className="section-title">
                        <h2>Experience From Top Global<span>Companies</span></h2>
                        <p>Oh…Hello! Our community of mentors/experts hail from some of the world’s best in breed organizations</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                     {/* <div className="top-gloabal-company-box"> */}
                     <Slider {...settings}>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/1.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/2.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/3.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/4.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/5.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/6.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/7.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/8.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/9.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/10.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/11.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/12.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/13.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/14.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/15.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/16.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/17.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/18.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/19.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/20.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/21.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/22.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/23.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/24.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/25.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/26.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/27.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/28.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/29.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/30.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/31.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/32.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/33.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/34.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/35.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/36.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/37.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/38.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/39.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/40.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/41.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/42.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/43.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/44.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/45.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/46.jpeg`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/47.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/48.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/49.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/50.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/51.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/52.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/53.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/54.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/55.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/56.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/57.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/58.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/59.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                        <div className="top-global-company-img-sec">
                            <Image
                                src={window.location.origin + `/assets/images/top-client/60.png`}
                                alt="Image"
                                className="top-global-company-img"
                            />
                        </div>
                     </Slider>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default TopGlobalCompany;
