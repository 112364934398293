import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const LandingCTA = (props) => {

  return (
    <>
      <div className="cta-sec sm-padding">
        <Container>
            <Row>
                <Col md={12}>
                    <div className="cta-card">
                        <h3>Don't get FOMO! Sign-up for updates on new mentors, VIP access to 100% guest featured newsletters, and more!</h3>
                        <a href="https://7zsw2cfdhld.typeform.com/to/ldgjI0iz" target="_blank" className="landing-theme-black-btn">
                            Learn More
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingCTA;
