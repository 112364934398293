import React, { useState, useEffect, Component,useRef,useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "./Dashboard.css";
import { Container, Row, Col, Image, Table } from "react-bootstrap";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import ImageLoader from "../Helper/ImageLoader";
import DashboardLoader from "../Handlers/Loaders/DashboardLoader";
import { t, withTranslation } from "react-multi-lang";



const DashboardIndex = (props) => {

    const [events, setEvents] = useState(null);

    useEffect(() => {
      if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
    }, []);

    useEffect(() => {
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = "https://apis.google.com/js/api.js";

      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.gapi) handleClientLoad();
      });
    }, []);

    const handleClientLoad = () => {
      window.gapi.load("client:auth2", initClient);
    };

    const SCOPES =
    "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar";

    const openSignInPopup = () => {
      window.gapi.auth2.authorize(
        { client_id: '1043573021715-pmr499il3hfkajrv4mb24n23n3qvb41n.apps.googleusercontent.com', scope: SCOPES },
        (res) => {
          if (res) {
            console.log(res);
            if (res.access_token)
              localStorage.setItem("access_token", res.access_token);

            // Load calendar events after authentication
              window.gapi.client.load("calendar", "v3", listUpcomingEvents);
          }
        }
      );
    }

    const initClient = () => {
      if (!localStorage.getItem("access_token")) {
        openSignInPopup();
      } else {
        // Get events if access token is found without sign in popup
        fetch(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=AIzaSyDUUHE6tG-lS0srybuDwaZ_xSRPnlK6g0I&orderBy=startTime&singleEvents=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
          .then((res) => {
            // Check if unauthorized status code is return open sign in popup
            if (res.status !== 401) {
              return res.json();
            } else {
              localStorage.removeItem("access_token");
              openSignInPopup();
            }
          })
          .then((data) => {
            if (data?.items) {
              setEvents(formatEvents(data.items));
            }
          });
      }
    };

    const listUpcomingEvents = () => {
      window.gapi.client.calendar.events
        .list({
          // Fetch events from user's primary calendar
          calendarId: "primary",
          showDeleted: false,
          singleEvents: true,
        })
        .then(function (response) {
          let events = response.result.items;

          if (events.length > 0) {
            setEvents(formatEvents(events));
          }
        });
    };

    const formatEvents = (list) => {
      return list.map((item) => ({
        title: item.summary,
        start: item.start.dateTime || item.start.date,
        end: item.end.dateTime || item.end.date,
      }));
    };
    console.log(events);

    

    return (
        <>
            <div className="main-wrapper dashboard-wrapper">
                {props.profile.loading ? (
                    <DashboardLoader />
                ) : (
                    <div className="dashboard-sec">
                        <Container>
                            <Row>
                                <Col md={4}>
                                    <div className="profile-img-sec">
                                        <ImageLoader
                                            image={props.profile.data.picture}
                                            className="user-img"
                                        />
                                    </div>
                                    <div className="edit-avatar-sec">
                                        <Link to="/edit-profile">{t("edit_avatar")}</Link>
                                    </div>
                                </Col>
                                <Col md={8} hidden>
                                    <div className="user-balance-sec">
                                        <p className="invite-amount">
                                            <Link to="#">{t("get")} $5</Link>
                                        </p>
                                        <p className="invite-desc">
                                            {t("invite_friends_to_join")}{" "}
                                            {configuration.get("configData.site_name")}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col ms={12}>
                                    <Table
                                        className="table table-striped dashboard-table mt-3"
                                        responsive
                                    >
                                        <tbody>
                                            <tr>
                                                <th>{t("your_name")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                {props.profile.data.name}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("your_username")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                @{props.profile.data.username}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>{t("your_email")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                {props.profile.data.email}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("video_call_amount")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                {props.profile.data.video_call_amount}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("your_facebook")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                {props.profile.data.facebook_link != null ? (
                                                                    <a
                                                                        target="_blank"
                                                                        href={props.profile.data.facebook_link}
                                                                    >
                                                                        {props.profile.data.facebook_link}
                                                                    </a>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("your_twitter")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                {props.profile.data.twitter_link ? (
                                                                    <a
                                                                        target="_blank"
                                                                        href={props.profile.data.twitter_link}
                                                                    >
                                                                        {props.profile.data.twitter_link}
                                                                    </a>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("your_bio")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic">
                                                                {props.profile.data.about}
                                                            </span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("edit")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr hidden>
                                                <th>{t("your_twitter_handle")}</th>
                                                <td>
                                                    <span>
                                                        <span>
                                                            <span class="data-dynamic"></span>
                                                            <Link to="/edit-profile">
                                                                <span class="data-static">{t("add")}</span>
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("your_category")}</th>
                                                <td>
                                                    {props.profile.data.category_name} -{" "}
                                                    {props.profile.data.subcategory_name}
                                                    {props.profile.data.is_model ?
                                                        <Link to="/categories">
                                                            <span class="data-static">{t("update_category")}</span>
                                                        </Link>
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("legal")}</th>
                                                <td>
                                                    <Link to="/page/terms">
                                                        <span class="data-static">{t("terms")}</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(DashboardIndex));
