import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const HowItsWorking = (props) => {

  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={9}>
                    <div className="section-title">
                        <h2>The power of connecting while<span>giving back</span></h2>
                        <p>We care. Actions > Words. 50+ global charities are integrated into the OhHello platform. Get expert guidance while they give back!</p>
                    </div>
                </Col>
            </Row>
            <Row className="sm-margin-top justify-content-md-center">
                <Col md={6} xl={4} lg={4}>
                   <div className="how-its-work-card">
                        <div className="how-its-work-number">
                            01
                        </div>
                        <div className="how-its-work-info">
                            <h4>Choose Your Experts</h4>
                            <p>Connect with the right person, at the right level, at the right company, to advance your career and answer questions.</p>
                        </div>
                   </div>
                </Col>
                <Col md={6} xl={4} lg={4}>
                   <div className="how-its-work-card">
                        <div className="how-its-work-number">
                            02
                        </div>
                        <div className="how-its-work-info">
                            <h4>Exchange Value</h4>
                            <p>Experts, aka Hellos, are available at all levels and budgets, no matter where the Advice Seekers, aka Ohs, are within their career.</p>
                        </div>
                   </div>
                </Col>
                <Col md={6} xl={4} lg={4}>
                   <div className="how-its-work-card">
                        <div className="how-its-work-number">
                            03
                        </div>
                        <div className="how-its-work-info">
                            <h4>Do Good</h4>
                            <p>A portion of the fee is paid directly to charity. Helping individuals and helping communities. What’s not to love?</p>
                        </div>
                   </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default HowItsWorking;
