import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
  Button,
  DropdownButton,
  Dropdown,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import configuration from "react-global-configuration";
import { useParams } from "react-router";
import { fetchSingleModelStart } from "../../store/actions/ModelAction";
import { sendVideocallUserStart } from '../../store/actions/VideoCall';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory } from 'react-router-dom';
import { t, withTranslation } from "react-multi-lang";
import { fetchMentorsAvailabilityUserStart } from "../../store/actions/AvailabilityAction";
import dayjs from "dayjs";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

const EventsRequest = (props) => {

  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState("");

  const [selectedTime, setSelectedTime] = useState();

  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchSingleModelStart({
      username: params.username,
    }));
  }, [params.username]);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender)
      if (!props.sendUserVideoCall.loading && Object.keys(props.sendUserVideoCall.data).length > 0)
        history.push("/my-tickets");
    setSkipRender(false);
  }, [props.sendUserVideoCall]);

  const formatNumber = num => {
    if (num < 10)
      return "0" + num;
    else
      return num;
  }

  const submitForm = (e) => {
    e.preventDefault();
    console.log(selectedStartEndTime);
    if (selectedDay && (selectedStartEndTime.start_time && selectedStartEndTime.end_time)) {
      let scheduleTime =
        selectedDay.year +
        "-" +
        formatNumber(selectedDay.month) +
        "-" +
        formatNumber(selectedDay.day);
      props.dispatch(sendVideocallUserStart({
        model_id: props.singleModel.data.user.user_id,
        date: scheduleTime,
        start_time: selectedStartEndTime.start_time,
        end_time: selectedStartEndTime.end_time,
        message: message,
      }));
    } else {
      setErrorMsg(t("select_a_time_slot"));
    }
  }


  const today = utils().getToday();

  const [message, setMessage] = useState("");
  const [selectedDay, setSelectedDay] = useState(today);

  const [selectedStartEndTime, setSelectedStartEndTime] = useState({
    start_time: "",
    end_time: "",
  });

  useEffect(() => {
    setSelectedTime();
    setSelectedStartEndTime({});
    let currentDate = dayjs(new Date())
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD HH:mm");
    if (props.singleModel.data.user) {
      props.dispatch(
        fetchMentorsAvailabilityUserStart({
          date:
            selectedDay.year + "-" + formatNumber(selectedDay.month) + "-" + formatNumber(selectedDay.day),
          mentor_id: props.singleModel.data.user.user_id,
          user_time_now: currentDate,
        })
      );
    }
  }, [selectedDay]);

  const TimeButton = ({ time }) => {

    return (
      <Button
        disabled={time.is_slot_booked == 1}
        className={`time-btn 
        ${selectedTime === time.start_time + time.end_time ? "active" : ""}
        ${time.is_slot_booked == 1 ? "strike-off" : ""}
          `}
        onClick={() => {
          if (time.is_slot_booked == 0) {
            setSelectedStartEndTime({
              start_time: time.start_time,
              end_time: time.end_time,
            });
            setSelectedTime(time.start_time + time.end_time);
            setErrorMsg("");
          }
        }}
      >{time.start_time} - {time.end_time}</Button>
    )

  }

  let selectedDate = new Date(`${selectedDay.year}-${formatNumber(selectedDay.month)}-${formatNumber(selectedDay.day)}`);
  return (
    <>
      <div className="events-request-sec">
        <Container>
          {props.singleModel.loading ?
            <Row>
              <Col md={5}>
                <Skeleton height={870} />
              </Col>
              <Col md={7}>
                <Skeleton height={870} />
              </Col>
            </Row>
            : <Row>
              <Col md={5}>
                <div className="events-request-box">
                  <div className="events-details-card">
                    <div className="events-request-user-img-sec">
                      <Image
                        src={props.singleModel.data.user.picture}
                        alt="user-image"
                        className="event-request-user-img"
                      />
                    </div>
                    <div className="events-request-user-info">
                      <h6>{t("phoneo_live_video_call_with")}</h6>
                      <h4>{props.singleModel.data.user.name}</h4>
                    </div>
                    <div className="events-request-user-list-sec">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span>{configuration.get("configData.video_call_duration")} {t("minutes")}</span>
                        </Media>
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polygon points="23 7 16 12 23 17 23 7"></polygon>
                            <rect
                              x="1"
                              y="5"
                              width="15"
                              height="14"
                              rx="2"
                              ry="2"
                            ></rect>
                          </svg>
                          <span>
                            {t("phoneo_live_details_provided_upon_confirmation")}
                          </span>
                        </Media>
                        {/* <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span>Up to 10 people</span>
                      </Media> */}
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <rect
                              x="1"
                              y="4"
                              width="22"
                              height="16"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                          </svg>
                          <span>{props.singleModel.data.user.video_call_amount_formatted}</span>
                        </Media>
                      </ul>
                    </div>
                    <div className="after-requesting-notes">
                      <h5>
                        {t("after_your_request_is_accepted_you’ll_receive_an_email_with_your")}:
                      </h5>
                      <ul className="list-unstyled">
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFF629"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                          <span>{t("selected_date_and_time")}</span>
                        </Media>
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFF629"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                          <span>{t("link_to_the_phoneo_live_call")}</span>
                        </Media>
                      </ul>
                      <p>
                        {t("want_more_info_check_out_our")}{" "}
                        <Link to="/page/Faq">{t("faq_page")}</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className="events-request-box">
                  <div className="event-request-select-date-time">
                    <h4>{t("select_a_date_and_time")}</h4>
                    <p>
                      {t("schedule_a_call_based_on_the_celebrity_availability")}
                    </p>
                  </div>
                  <div className="date-time-picker-card">
                    <div className="date-picker">
                      <h4 className="new-date-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          fill="none"
                          viewBox="0 0 21 21"
                        >
                          <path
                            fill="#ADAEB5"
                            stroke="#ADAEB5"
                            d="M17.625 2.9h-2.85V1.475a.475.475 0 10-.95 0V2.9h-6.65V1.475a.475.475 0 10-.95 0V2.9h-2.85A2.378 2.378 0 001 5.275v12.35A2.378 2.378 0 003.375 20h14.25A2.377 2.377 0 0020 17.625V5.275A2.377 2.377 0 0017.625 2.9zm1.425 14.725a1.425 1.425 0 01-1.425 1.425H3.375a1.425 1.425 0 01-1.425-1.425V9.55h17.1v8.075zm0-9.025H1.95V5.275c0-.787.637-1.425 1.425-1.425h2.85v1.425a.475.475 0 10.95 0V3.85h6.65v1.425a.475.475 0 10.95 0V3.85h2.85a1.425 1.425 0 011.425 1.425V8.6z"
                          ></path>
                        </svg>
                        Date
                      </h4>
                      <Calendar
                        value={selectedDay}
                        onChange={setSelectedDay}
                        shouldHighlightWeekends
                        minimumDate={utils().getToday()}
                      />
                    </div>
                    <div className="time-picker">
                      <h4 className="new-date-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="20"
                          fill="none"
                          viewBox="0 0 19 20"
                        >
                          <path
                            fill="#ADAEB5"
                            d="M9.5 17.6a7.6 7.6 0 100-15.2 7.6 7.6 0 000 15.2zM9.5.5a9.5 9.5 0 110 19C4.247 19.5 0 15.225 0 10A9.5 9.5 0 019.5.5zm.475 4.75v4.988l4.275 2.536-.713 1.169L8.55 10.95v-5.7h1.425z"
                          ></path>
                        </svg>
                        Time
                      </h4>
                      <div className="date-picker-selected-card">
                        <h6>{selectedDate.toLocaleString('en-us', { weekday: 'long' })}, {selectedDate.toLocaleString('default', { month: 'long' })} {selectedDay.day}</h6>
                        {props.profile ?
                          <p>{t("all_times_in")} {props.profile.data.time_formatted}</p>
                          : ''}
                      </div>

                      {props.availability.loading ? (
                        "Loading..."
                      ) : (
                        <div className="time-picker">
                          <div className="time-picker-box">
                            <div className="time-picker-card">
                              {props.availability.data.trainer_availabilities
                                .length > 0
                                ? props.availability.data.trainer_availabilities.map(
                                  (trainer_slot, index) => (
                                    <TimeButton time={trainer_slot} />
                                  )
                                )
                                : null}
                            </div>
                            <div className="text-danger large">{errorMsg}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="event-selection-sec mt-5">
                    {/* <div class="event-request-select-date-time">
                    <h4>How many participants?</h4>
                    <p>You can have a max of 10 participants</p>
                  </div>
                  <div className="event-selection-dropdown">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select an option"
                    >
                      <Dropdown.Item href="#/action-1">
                        Small group (1-5)
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Large group (5-10)
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Unsure</Dropdown.Item>
                    </DropdownButton>
                  </div> */}
                    <div className="selection-text-area">
                      <div className="event-request-select-date-time mt-4">
                        <h4 className="m-0">{t("what_do_you_want_to_talk_about")}</h4>
                        <p>{t("share_the_thoughts_that_you_have_behind_the_call")}</p>
                      </div>
                      <Form onSubmit={submitForm}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="eg:- My friends and I have been fans of you for years! We just want to see what you're working on and share some of the impacts you've had on our lives"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                          />
                        </Form.Group>
                        <div className="selection-payment-btn">
                          <Button
                            type="submit"
                            disabled={props.sendUserVideoCall.buttonDisable}
                          >
                            {props.sendUserVideoCall.loadingButtonContent ?
                              props.sendUserVideoCall.loadingButtonContent
                              : t("send_request")}</Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  sendUserVideoCall: state.videocall.sendUserVideoCall,
  availability: state.mentorAvailability.mentorAvailability,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EventsRequest));
