
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    FETCH_MENTORS_AVAILABILITY_START,
    FETCH_MENTORS_AVAILABILITY_USER_START,
    DELETE_MENTORS_AVAILABILITY_START,
    SAVE_MENTORS_AVAILABILITY_START,
    SYNC_GOOGLE_CALENDAR_EVENTS_START,
    SYNC_OUTLOOK_CALENDAR_EVENTS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";

import {
    fetchMentorsAvailabilitySuccess,
    fetchMentorsAvailabilityFailure,
    fetchMentorsAvailabilityUserSuccess,
    fetchMentorsAvailabilityUserFailure,
    deleteMentorsAvailabilitySuccess,
    deleteMentorsAvailabilityFailure,
    saveMentorsAvailabilitySuccess,
    saveMentorsAvailabilityFailure,
    fetchMentorsAvailabilityStart,
    syncOutlookCalendarSuccess,
    syncOutlookCalendarFailure,
    syncGoogleCalendarSuccess,
    syncGoogleCalendarFailure,
} from "../actions/AvailabilityAction";

function* fetchMentorsAvailabilityAPI(action) {
    try {
        const response = yield api.postMethod("mentor_availabilities", action.data);

        if (response.data.success) {
            yield put(fetchMentorsAvailabilitySuccess(response.data.data));
        } else {
            yield put(fetchMentorsAvailabilityFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchMentorsAvailabilityFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchMentorsAvailabilityUserAPI(action) {
    try {
        const response = yield api.postMethod("mentor_availabilities_for_user", action.data);

        if (response.data.success) {
            yield put(fetchMentorsAvailabilityUserSuccess(response.data.data));
        } else {
            yield put(fetchMentorsAvailabilityUserFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchMentorsAvailabilityUserFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* saveMentorAvailabilityAPI(action) {
    try {
        const response = yield api.postMethod("mentor_availabilities_save", action.data);

        if (response.data.success) {
            yield put(saveMentorsAvailabilitySuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(fetchMentorsAvailabilityStart({ date: action.data.date }));
            yield put(createNotification(notificationMessage));
        } else {
            yield put(saveMentorsAvailabilityFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(saveMentorsAvailabilityFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* syncGoogleCalendarAPI(action) {
    try {
        const response = yield api.postMethod("google_calendar_events", action.data);

        if (response.data.success) {
            yield put(syncOutlookCalendarSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(fetchMentorsAvailabilityStart({ date: action.data.date }));
            yield put(createNotification(notificationMessage));
        } else {
            yield put(syncOutlookCalendarFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(saveMentorsAvailabilityFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* deleteMentorsAvailabilityAPI(action) {
    try {
        const response = yield api.postMethod("mentor_availabilities_delete", action.data);

        if (response.data.success) {
            yield put(deleteMentorsAvailabilitySuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(deleteMentorsAvailabilityFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(deleteMentorsAvailabilityFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

export default function* pageSaga() {
    yield all([yield takeLatest(FETCH_MENTORS_AVAILABILITY_START, fetchMentorsAvailabilityAPI)]);
    yield all([yield takeLatest(FETCH_MENTORS_AVAILABILITY_USER_START, fetchMentorsAvailabilityUserAPI)]);
    yield all([yield takeLatest(DELETE_MENTORS_AVAILABILITY_START, deleteMentorsAvailabilityAPI)]);
    yield all([yield takeLatest(SAVE_MENTORS_AVAILABILITY_START, saveMentorAvailabilityAPI)]);
    yield all([yield takeLatest(SYNC_GOOGLE_CALENDAR_EVENTS_START, syncGoogleCalendarAPI)]);
}
