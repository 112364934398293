import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ADD_BILLING_ACCOUNT_START,
  GET_BILLING_ACCOUNT_START,
  MAKE_DEFAULT_BILLING_ACCOUNT_START,
  DELETE_BILLING_ACCOUNT_START,
  FETCH_SINGLE_BILLING_ACCOUNT_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  addBillingAccountSuccess,
  addBillingAccountFailure,
  getBillingAccountSuccess,
  getBillingAccountFailure,
  getBillingAccountStart,
  makeDefaultBillingAccountSuccess,
  makeDefaultBillingAccountFailure,
  deleteBillingAccountSuccess,
  deleteBillingAccountFailure,
  fetchSingleBillingAccountSuccess,
  fetchSingleBillingAccountFailure,
} from "../actions/BillingAccountAction";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* addBillingAccountAPI() {
  try {
    const inputData = yield select(
      (state) => state.billingAccount.addBillingAccountInput.inputData
    );
    const response = yield api.postMethod("billing_accounts_save", inputData);

    if (response.data.success) {
      yield put(addBillingAccountSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/billing-accounts");
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(addBillingAccountFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addBillingAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getBillingAccountAPI() {
  try {
    const response = yield api.postMethod("billing_accounts_list");

    if (response.data.success) {
      yield put(getBillingAccountSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(getBillingAccountFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getBillingAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* makeDefaultBillingAccountAPI() {
  try {
    const inputData = yield select(
      (state) => state.billingAccount.makeDefault.inputData
    );
    const response = yield api.postMethod(
      "billing_accounts_default",
      inputData
    );

    if (response.data.success) {
      yield put(makeDefaultBillingAccountSuccess(response.data));
      yield put(getBillingAccountStart());
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(makeDefaultBillingAccountFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(makeDefaultBillingAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteAccountBillingAccountAPI() {
  try {
    const inputData = yield select(
      (state) => state.billingAccount.deleteAccount.inputData
    );
    const response = yield api.postMethod("billing_accounts_delete", inputData);

    if (response.data.success) {
      yield put(deleteBillingAccountSuccess(response.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/billing-accounts");
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(deleteBillingAccountFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteBillingAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleBillingAccountAPI() {
  try {
    const inputData = yield select(
      (state) => state.billingAccount.singleAccount.inputData
    );
    const response = yield api.postMethod("users_accounts_save", inputData);

    if (response.data.success) {
      yield put(fetchSingleBillingAccountSuccess(response.data));
      yield put(getBillingAccountStart());
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchSingleBillingAccountFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleBillingAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ADD_BILLING_ACCOUNT_START, addBillingAccountAPI),
  ]);
  yield all([
    yield takeLatest(GET_BILLING_ACCOUNT_START, getBillingAccountAPI),
  ]);
  yield all([
    yield takeLatest(
      MAKE_DEFAULT_BILLING_ACCOUNT_START,
      makeDefaultBillingAccountAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      DELETE_BILLING_ACCOUNT_START,
      deleteAccountBillingAccountAPI
    ),
  ]);
  yield all([
    yield takeLatest(
      FETCH_SINGLE_BILLING_ACCOUNT_START,
      fetchSingleBillingAccountAPI
    ),
  ]);
}
