import {
    FETCH_MENTORS_AVAILABILITY_START,
    FETCH_MENTORS_AVAILABILITY_SUCCESS,
    FETCH_MENTORS_AVAILABILITY_FAILURE,
    FETCH_MENTORS_AVAILABILITY_USER_START,
    FETCH_MENTORS_AVAILABILITY_USER_SUCCESS,
    FETCH_MENTORS_AVAILABILITY_USER_FAILURE,
    DELETE_MENTORS_AVAILABILITY_START,
    DELETE_MENTORS_AVAILABILITY_SUCCESS,
    DELETE_MENTORS_AVAILABILITY_FAILURE,
    SAVE_MENTORS_AVAILABILITY_START,
    SAVE_MENTORS_AVAILABILITY_SUCCESS,
    SAVE_MENTORS_AVAILABILITY_FAILURE,
    SYNC_GOOGLE_CALENDAR_EVENTS_START,
    SYNC_GOOGLE_CALENDAR_EVENTS_SUCCESS,
    SYNC_GOOGLE_CALENDAR_EVENTS_FAILURE,
    SYNC_OUTLOOK_CALENDAR_EVENTS_START,
    SYNC_OUTLOOK_CALENDAR_EVENTS_SUCCESS,
    SYNC_OUTLOOK_CALENDAR_EVENTS_FAILURE,
  } from "./ActionConstant";
  
  export function fetchMentorsAvailabilityStart(data) {
    return {
      type: FETCH_MENTORS_AVAILABILITY_START,
      data,
    };
  }
  
  export function fetchMentorsAvailabilitySuccess(data) {
    return {
      type: FETCH_MENTORS_AVAILABILITY_SUCCESS,
      data,
    };
  }
  
  export function fetchMentorsAvailabilityFailure(error) {
    return {
      type: FETCH_MENTORS_AVAILABILITY_FAILURE,
      error,
    };
  }
  
  
  export function fetchMentorsAvailabilityUserStart(data) {
    return {
      type: FETCH_MENTORS_AVAILABILITY_USER_START,
      data,
    };
  }
  
  export function fetchMentorsAvailabilityUserSuccess(data) {
    return {
      type: FETCH_MENTORS_AVAILABILITY_USER_SUCCESS,
      data,
    };
  }
  
  export function fetchMentorsAvailabilityUserFailure(error) {
    return {
      type: FETCH_MENTORS_AVAILABILITY_USER_FAILURE,
      error,
    };
  }
  
  export function deleteMentorsAvailabilityStart(data) {
    return {
      type: DELETE_MENTORS_AVAILABILITY_START,
      data,
    };
  }
  
  export function deleteMentorsAvailabilitySuccess(data) {
    return {
      type: DELETE_MENTORS_AVAILABILITY_SUCCESS,
      data,
    };
  }
  
  export function deleteMentorsAvailabilityFailure(error) {
    return {
      type: DELETE_MENTORS_AVAILABILITY_FAILURE,
      error,
    };
  }

  export function saveMentorsAvailabilityStart(data) {
    return {
      type: SAVE_MENTORS_AVAILABILITY_START,
      data,
    };
  }
  
  export function saveMentorsAvailabilitySuccess(data) {
    return {
      type: SAVE_MENTORS_AVAILABILITY_SUCCESS,
      data,
    };
  }
  
  export function saveMentorsAvailabilityFailure(error) {
    return {
      type: SAVE_MENTORS_AVAILABILITY_FAILURE,
      error,
    };
  }

  export function syncGoogleCalendarStart(data) {
    return {
      type: SYNC_GOOGLE_CALENDAR_EVENTS_START,
      data,
    };
  }
  
  export function syncGoogleCalendarSuccess(data) {
    return {
      type: SYNC_GOOGLE_CALENDAR_EVENTS_SUCCESS,
      data,
    };
  }
  
  export function syncGoogleCalendarFailure(error) {
    return {
      type: SYNC_GOOGLE_CALENDAR_EVENTS_FAILURE,
      error,
    };
  }

  export function syncOutlookCalendarStart(data) {
    return {
      type: SYNC_OUTLOOK_CALENDAR_EVENTS_START,
      data,
    };
  }
  
  export function syncOutlookCalendarSuccess(data) {
    return {
      type: SYNC_OUTLOOK_CALENDAR_EVENTS_SUCCESS,
      data,
    };
  }
  
  export function syncOutlookCalendarFailure(error) {
    return {
      type: SYNC_OUTLOOK_CALENDAR_EVENTS_FAILURE,
      error,
    };
  }
  