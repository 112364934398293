import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";

const LandingFooter = (props) => {

  const t = useTranslation("")

  return (
    <>
      <div className="landing-footer-sec">
        <Container>
          <Row>
            <Col md={6} xl={6} lg={6} className="resp-mrg-btm-xs">
              <div className="landing-footer-left-sec">
                <div className="landing-footer-logo-sec">
                  <Link to="/">
                    <Image
                      src={configuration.get("configData.site_logo")}
                      alt={configuration.get("configData.site_name")}
                      className="landing-footer-logo"
                    />
                  </Link>
                </div>
                <div className="landing-footer-desc">
                  <p>{configuration.get("configData.footer_description")}</p>
                </div>
                <div className="landing-footer-social-link-sec">
                  <ul className="list-unstyled landing-footer-social-link">
                  {configuration.get("configData.linkedin_link") ?
                      <li>
                        <a href={configuration.get("configData.linkedin_link")} target="_blank">
                          <Image
                            src={window.location.origin + `/assets/images/landing/social-icon-4.svg`}
                            alt="Image"
                            className="social-icons"
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.tiktok_link") ?
                      <li>
                        <a href={configuration.get("configData.tiktok_link")} target="_blank">
                          <Image
                            src={window.location.origin + `/assets/images/landing/social-icon-5.svg`}
                            alt="Image"
                            className="social-icons"
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.instagram_link") ?
                      <li>
                        <a href={configuration.get("configData.instagram_link")} target="_blank">
                          <Image
                            src={window.location.origin + `/assets/images/landing/social-icon-1.svg`}
                            alt="Image"
                            className="social-icons"
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.youtube_link") ?
                      <li>
                        <a href={configuration.get("configData.youtube_link")} target="_blank">
                          <Image
                            src={window.location.origin + `/assets/images/landing/social-icon-2.svg`}
                            alt="Image"
                            className="social-icons"
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.twitter_link") ?
                      <li>
                        <a href={configuration.get("configData.twitter_link")} target="_blank">
                          <Image
                            src={window.location.origin + `/assets/images/landing/social-icon-3.svg`}
                            alt="Image"
                            className="social-icons"
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                  </ul>
                </div>
                {configuration.get("configData.contact_email") ?
                  <Link to={`mailto:${configuration.get(
                    "configData.contact_email"
                  )}`} className="email-link">
                    {configuration.get("configData.contact_email")}</Link>
                  :
                  null
                }
              </div>
            </Col>
            <Col md={6} xl={6} lg={6}>
              <div className="landing-footer-right-sec">
                <div className="landing-footer-link-card">
                  <h4>{t("about")}</h4>
                  <ul className="list-unstyled footer-link">
                    {configuration
                      .get("configData.footer_section_1")
                      .map((static_page, index) => (
                        <li>
                          <Link to={`/page/${static_page.static_page_unique_id}`}
                            key={static_page.static_page_unique_id}
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            }>
                            {static_page.title}
                          </Link>
                        </li>
                      ))}
                      <li>
                      <a href="https://blog.ohhello.io/" target="_blank">
                        {t("blog")}
                      </a>
                    </li>
                    <li>
                      <a href="http://ohhello.printify.me/" target="_blank">
                        Shop {configuration.get("configData.site_name")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="landing-footer-link-card">
                  <h4>{t("solutions")}</h4>
                  <ul className="list-unstyled footer-link">
                    {configuration
                      .get("configData.footer_section_2")
                      .map((static_page, index) => (
                        <li>
                          <Link to={`/page/${static_page.static_page_unique_id}`}
                            key={static_page.static_page_unique_id}
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            }>
                            {static_page.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
                {/* <div className="landing-footer-link-card">
                  <h4>{t("popular_features")}</h4>
                  <ul className="list-unstyled footer-link">
                    <li>
                      <Link to="/page/embed">
                      {t("embed")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/availability">
                      {t("availability")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/sending-notifications">
                      {t("sending_notifications")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/using-calendly-mobile">
                      {t("using_calendly_mobile")}
                      </Link>
                    </li>
                   
                  </ul>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingFooter;
