import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_MODEL_DETAILS_START,
  FETCH_MORE_MODEL_DETAILS_START,
  FETCH_SINGLE_MODEL_DETAILS_START,
  FETCH_MODEL_RATING_START,
  FETCH_MODEL_SUB_START,
  FETCH_MODEL_SINGLE_POST_START,
  FETCH_MODEL_POSTS_START,
  FETCH_MODEL_SUGGESTION_START,
  MODEL_CATEGORY_UPDATE_START,
  FETCH_HOME_START,
  MODEL_FILTERS_START,
  FEATURED_MODELS_START,
  FETCH_MORE_MODEL_POSTS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  fetchModelDetailsFailure,
  fetchModelDetailsSuccess,
  fetchModelSubFailure,
  fetchModelSubSuccess,
  fetchSingleModelFailure,
  fetchSingleModelSuccess,
  fetchModelRatingSuccess,
  fetchModelRatingFailure,
  fetchModelPostsSuccess,
  fetchModelPostsFailure,
  fetchModelSinglePostSuccess,
  fetchModelSinglePostFailure,
  fetchModelSuggestionSuccess,
  fetchModelSuggestionFailure,
  modelCategoryUpdateSuccess,
  modelCategoryUpdateFailure,
  fetchHomeSuccess,
  fetchHomeFailure,
  modelFiltersSuccess,
  modelFiltersFailure,
  featuredModelsSuccess,
  featuredModelsFailure,
} from "../actions/ModelAction";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* fetchSingleModelAPI() {
  try {
    const inputData = yield select(
      (state) => state.model.singleModel.inputData
    );
    const response = yield api.postMethod("model_profile", inputData);
    if (response.data.success) {
      yield put(fetchSingleModelSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchSingleModelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleModelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelAPI(action) {
  try {
    const response = yield api.postMethod("models_list", action.data);
    if (response.data.success) {
      yield put(fetchModelDetailsSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchModelDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchModelDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelSubAPI() {
  try {
    const inputData = yield select((state) => state.model.modelSub.inputData);
    const response = yield api.postMethod(
      "model_subscriptions_list",
      inputData
    );
    if (response.data.success) {
      yield put(fetchModelSubSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchModelSubFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchModelSubFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelRatingAPI() {
  try {
    const inputData = yield select(
      (state) => state.model.modelRating.inputData
    );
    const response = yield api.postMethod("model_ratings_list", inputData);
    if (response.data.success) {
      yield put(fetchModelRatingSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchModelRatingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchModelRatingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelPostAPI(action) {
  try {
    const response = yield api.postMethod("model_posts_list", action.data);
    if (response.data.success) {
      yield put(fetchModelPostsSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchModelPostsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchModelPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelSinglePostAPI() {
  try {
    const inputData = yield select(
      (state) => state.model.modelSinglePost.inputData
    );
    const response = yield api.postMethod("model_posts_view", inputData);
    if (response.data.success) {
      yield put(fetchModelSinglePostSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchModelSinglePostFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchModelSinglePostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelSuggestionAPI() {
  try {
    const inputData = yield select(
      (state) => state.model.modelSuggestion.inputData
    );
    const response = yield api.postMethod("model_suggestions_index", inputData);
    if (response.data.success) {
      yield put(fetchModelSuggestionSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchModelSuggestionFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchModelSuggestionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* modelCategoryUpdateAPI() {
  try {
    const inputData = yield select((state) => state.model.modelCat.inputData);
    const response = yield api.postMethod("profile_categories_save", inputData);
    if (response.data.success) {
      yield put(modelCategoryUpdateSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      if (
        response.data.data &&
        response.data.data.is_subscription_redirect == 1
      ) {
        window.location.assign("/create-subscription");
      }
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(modelCategoryUpdateFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(modelCategoryUpdateFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchHomeAPI() {
  try {
    const response = yield api.postMethod("home");
    if (response.data.success) {
      yield put(fetchHomeSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchHomeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchHomeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchModelFiltersAPI() {
  try {
    const inputData = yield select(
      (state) => state.model.modelFilters.inputData
    );
    const response = yield api.postMethod("model_search_filters", inputData);
    if (response.data.success) {
      yield put(modelFiltersSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(modelFiltersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(modelFiltersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* featuredModelAPI() {
  try {
    const response = yield api.postMethod("featured_models");
    if (response.data.success) {
      yield put(featuredModelsSuccess(response.data.data));
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(featuredModelsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(featuredModelsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_SINGLE_MODEL_DETAILS_START, fetchSingleModelAPI),
    yield takeLatest(FETCH_MODEL_DETAILS_START, fetchModelAPI),
    yield takeLatest(FETCH_MORE_MODEL_DETAILS_START, fetchModelAPI),
    yield takeLatest(FETCH_MODEL_SUB_START, fetchModelSubAPI),
    yield takeLatest(FETCH_MODEL_RATING_START, fetchModelRatingAPI),
    yield takeLatest(FETCH_MODEL_POSTS_START, fetchModelPostAPI),
    yield takeLatest(FETCH_MORE_MODEL_POSTS_START, fetchModelPostAPI),
    yield takeLatest(FETCH_MODEL_SINGLE_POST_START, fetchModelSinglePostAPI),
    yield takeLatest(FETCH_MODEL_SUGGESTION_START, fetchModelSuggestionAPI),
    yield takeLatest(MODEL_CATEGORY_UPDATE_START, modelCategoryUpdateAPI),
    yield takeLatest(FETCH_HOME_START, fetchHomeAPI),
    yield takeLatest(MODEL_FILTERS_START, fetchModelFiltersAPI),
    yield takeLatest(FEATURED_MODELS_START, featuredModelAPI),
  ]);
}
