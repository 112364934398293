import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Payments/BillingAccounts/BillingAccount.css";
import { fetchStripeConnectStart, getStripeConnectUrlStart, deleteStripeConnectStart } from "../../store/actions/UserAction";

const StripeConnect = (props) => {
    const [inputData, setInputData] = useState({});

    useEffect(() => {
        if (props.stripeConnect.loading) props.dispatch(fetchStripeConnectStart());
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(getStripeConnectUrlStart(inputData));
    };

    const handleRemove = (event) => {
        event.preventDefault();
        props.dispatch(deleteStripeConnectStart());
    }

    return (
        <>
            {props.stripeConnect.loading ? (
                ''
            ) : (
                <div className="main-wrapper">
                    <Container>
                        <div className="add-bank-card">
                            <h3 className="title">Stripe Connect</h3>
                            <h6>As a Creator, you may charge users to create request, therefore, you are required to connect your stripe account so our system can automatically transfer your payments into your stripe account. Stripe will then transfer your funds into your bank account.</h6>

                            <br></br>
                            <p><b>Account Status: {props.stripeConnect.data.account_status == 0 ? 'Not connected' : props.stripeConnect.data.account_status == 2 ? 'Verified' : 'Pending'}</b></p>
                            <p><b>Email Address: {props.stripeConnect.data.stripe_connected_account_email}</b></p>
                            <p><b>Is Connected: {props.stripeConnect.data.stripe_connected_account_email ? 'Yes' : 'No'}</b></p>

                            {props.stripeConnect.data.stripe_connected_account_email == '' ?
                                <Form
                                    className="add-bank-form"
                                    onSubmit={handleSubmit}
                                    method="POST"
                                >
                                    <Row>

                                        <Col md={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Email Id *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Email Id"
                                                    value={inputData.stripe_email}
                                                    name="stripe_email"
                                                    onChange={(event) => {
                                                        setInputData({
                                                            ...inputData,
                                                            stripe_email: event.currentTarget.value,
                                                        });
                                                    }}
                                                />
                                            </Form.Group>

                                            <div className="btn-continue-sec">
                                                <Button
                                                    className="btn-continue"
                                                    type="submit"
                                                    disabled={props.stripeConnectUrl.buttonDisable}
                                                >
                                                    {props.stripeConnectUrl.loadingButtonContent !== null
                                                        ? props.stripeConnectUrl.loadingButtonContent
                                                        : "Submit"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                : ''}
                            {props.stripeConnect.data.account_status == 1 ?

                                <>
                                    <a
                                        href={props.stripeConnect.data.express_connect_url ? props.stripeConnect.data.express_connect_url : '#'}
                                        target={props.stripeConnect.data.express_connect_url ? "_blank" : ''}
                                        className="btn-continue"
                                    >
                                        Click me to Connect
                                    </a>
                                </>
                                : props.stripeConnect.data.account_status == 2 ?
                                    <>
                                        <Button
                                            className="mr-2"
                                            type="submit"
                                            onClick={(event) =>
                                                handleRemove(event)
                                            }
                                        >
                                            Remove
                                        </Button>

                                        <a
                                            href={props.stripeConnect.data.login_url ? props.stripeConnect.data.login_url : '#'}
                                            target={props.stripeConnect.data.login_url ? "_blank" : ''}
                                            className="btn-continue"
                                        >
                                            View
                                        </a>

                                    </>
                                    : ''
                            }
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
};

const mapStateToPros = (state) => ({
    stripeConnect: state.users.stripeConnect,
    stripeConnectUrl: state.users.getStripeConnectUrl,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StripeConnect);
