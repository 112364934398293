import {
  GET_KYC_DOCUMENT_START,
  GET_KYC_DOCUMENT_SUCCESS,
  GET_KYC_DOCUMENT_FAILURE,
  ADD_KYC_DOCUMENT_START,
  ADD_KYC_DOCUMENT_SUCCESS,
  ADD_KYC_DOCUMENT_FAILURE,
  PERSONA_KYC_VERIFICATION_START,
  PERSONA_KYC_VERIFICATION_SUCCESS,
  PERSONA_KYC_VERIFICATION_FAILURE,
} from "./ActionConstant";

// Get kyc document actions.

export function getKycDocumentStart(data) {
  return {
    type: GET_KYC_DOCUMENT_START,
    data,
  };
}

export function getKycDocumentSuccess(data) {
  return {
    type: GET_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function getKycDocumentFailure(error) {
  return {
    type: GET_KYC_DOCUMENT_FAILURE,
    error,
  };
}

// add kyc document actions.

export function addKycDocumentStart(data) {
  return {
    type: ADD_KYC_DOCUMENT_START,
    data,
  };
}

export function addKycDocumentSuccess(data) {
  return {
    type: ADD_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function addKycDocumentFailure(error) {
  return {
    type: ADD_KYC_DOCUMENT_FAILURE,
    error,
  };
}

//persona kyc complete
export const personaKycVerificationStart = data => ({ type: PERSONA_KYC_VERIFICATION_START, data });
export const personaKycVerificationSuccess = data => ({ type: PERSONA_KYC_VERIFICATION_SUCCESS, data });
export const personaKycVerificationFailure = error => ({ type: PERSONA_KYC_VERIFICATION_FAILURE, error });
