import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Image,
  Modal,
  // Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import { sendWithDrawRequestStart } from "../../store/actions/WithDrawAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import { t, withTranslation } from "react-multi-lang";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const WithDrawModelSec = (props) => {
  useEffect(() => {
    if (props.bankAccount.loading) props.dispatch(getBankAccountStart());
  }, []);
  const [inputData, setInputData] = useState({});

  // const handleSubmit = (values) => {
  // event.preventDefault();
  // if (
  //  values < props.wallet.data.user_withdrawals_min_amount
  // ) {
  //   const notificationMessage = getSuccessNotificationMessage(
  //     t("please_enter_min_amount")
  //   );
  //   props.dispatch(createNotification(notificationMessage));
  //   return false;
  // }
  // props.dispatch(sendWithDrawRequestStart(inputData));
  //   setTimeout(() => {
  //     if (
  //       props.withDraw.errorCode == 0 ||
  //       props.withDraw.errorCode == undefined
  //     ) {
  //       props.handleClose();
  //     }
  //   }, 1000);
  // };

  const handleSubmit = (values) => {
    props.dispatch(sendWithDrawRequestStart(values));
    console.log(values);
  };

  const withDrawModelSchema = Yup.object().shape({
    // user_billing_account_id: Yup.string().when("reward_withdrawal", {
    //   is: "0",
    //   then: Yup.string().required(t("bank_account_is_required"))
    // }),
    requested_amount: Yup.number()
      .min(10, t("the_min_withdraw_limit_is_10"))
      .required(t("amount_is_required")),
  });

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
      className="review-modal withdraw-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>{t("withdraw_money")}</h4>
        </Modal.Title>
        <i className="fas fa-times close-icon" onClick={props.handleClose}></i>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-white">
          {t("wallent_balance")}:{" "}
          {props.wallet.data.user_wallet
            ? props.wallet.data.user_wallet.remaining_formatted
            : null}
        </h5>
        <Formik
          initialValues={{
            // title: "",
            user_billing_account_id: "",
            requested_amount: "",
            reward_withdrawal: "0",
          }}
          validationSchema={withDrawModelSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, values, isSubmitting, setFieldValue }) => (
            <Form className="withdraw-modal-form" noValidate>
              <div
                role="group"
                aria-labelledby="my-radio-group"
                className="widthdarw-money-bank-radio-sec"
              >
                <div className="radio">
                  <Field
                    id="reward_withdrawal_bank"
                    type="radio"
                    name="reward_withdrawal"
                    value="0"
                  />
                  <label for="reward_withdrawal_bank" className="radio-label">
                    {t("stripe")}
                  </label>
                </div>
                <div className="radio">
                  <Field
                    id="reward_withdrawal_tremendous"
                    type="radio"
                    name="reward_withdrawal"
                    value="1"
                  />
                  <label
                    for="reward_withdrawal_tremendous"
                    className="radio-label"
                  >
                    {t("tremendous")}
                  </label>
                </div>
              </div>
              {/* {values.reward_withdrawal == 0 ?
                props.bankAccount.loading ? (
                  t("loading")
                ) : props.bankAccount.data.billing_accounts.length > 0 ? (
                  <>
                    <Field
                      as="select"
                      name="user_billing_account_id"
                      className="form-control"
                    >
                      <option>{t("choose_bank_account")}</option>
                      {props.bankAccount.data.billing_accounts.map((account) => (
                        <option
                          value={account.user_billing_account_id}
                          name="user_billing_account_id"
                        >
                          {account.nickname} - {account.account_number}{" "}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      component={"div"}
                      name="user_billing_account_id"
                      className="text-danger text-right"
                    />
                  </>
                ) : (
                  <p className="withdraw-note">
                    <Link to="/add-billing-account" target="_blank">
                      {t("please_add_bank_account")}
                    </Link>
                  </p>
                ) : null} */}

              {values.reward_withdrawal == 0 ? (
                props.profile.data.stripe_connected_account_email == "" ? (
                  <p className="withdraw-note">
                    <Link to="/stripe-connect" target="_blank">
                      {t("stripe_is_not_configured")}
                    </Link>
                  </p>
                ) : null
              ) : null}

              <p className="withdraw-note mt-3">
                {t("min_withdraw_limit_is")}:{" "}
                {props.wallet.data.user_withdrawals_min_amount_formatted}
              </p>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fas fa-dollar-sign"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Field
                  placeholder={t("amount")}
                  name="requested_amount"
                  required
                  type="number"
                  min="0"
                  step="any"
                  className="form-control"
                  // value={inputData.requested_amount}
                  value={values.requested_amount}
                />
              </InputGroup>
              <ErrorMessage
                component={"div"}
                name="requested_amount"
                className="text-danger text-right"
              />

              {/* {props.bankAccount.loading ? (
                t("loading")
              ) : props.bankAccount.data.billing_accounts.length > 0 ||
                values.reward_withdrawal == "1" ? ( */}
              <Button
                className="btn-withdraw-modal"
                type="submit"
                disabled={
                  props.withDraw.buttonDisable ||
                  (values.reward_withdrawal == 0 &&
                    props.profile.data.stripe_connected_account_email == "")
                }
              >
                {props.withDraw.loadingButtonContent != null
                  ? props.withDraw.loadingButtonContent
                  : t("submit")}
              </Button>
              {/* ) : (
                <Button className="btn-withdraw-modal" disabled>
                  {t("submit")}
                </Button>
              )} */}
            </Form>
          )}
        </Formik>
        {/* <Form className="withdraw-modal-form" onSubmit={handleSubmit}>
          {props.bankAccount.loading ? (
            t("loading")
          ) : props.bankAccount.data.billing_accounts.length > 0 ? (
            <Form.Group
              controlId="exampleForm.ControlSelect1"
              className="select-account"
            >
              <Form.Control
                as="select"
                onChange={(event) =>
                  setInputData({
                    ...inputData,
                    user_billing_account_id: event.currentTarget.value,
                  })
                }
              >
                <option>{t("choose_bank_account")}</option>
                {props.bankAccount.data.billing_accounts.map((account) => (
                  <option
                    value={account.user_billing_account_id}
                    name="user_billing_account_id"
                  >
                    {account.nickname} - {account.account_number}{" "}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : (
            <p className="withdraw-note">
              <Link to="/add-billing-account" target="_blank">
               {t("please_add_bank_account")}
              </Link>
            </p>
          )}

          <p className="withdraw-note">
           {t("min_withdraw_limit_is")}:{" "}
            {props.wallet.data.user_withdrawals_min_amount_formatted}
          </p>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fas fa-dollar-sign"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder={t("amount")}
              name="requested_amount"
              required
              type="number"
              min="0"
              step="any"
              value={inputData.requested_amount}
              onChange={(event) =>
                setInputData({
                  ...inputData,
                  requested_amount: event.currentTarget.value,
                })
              }
            />
          </InputGroup>

          {props.bankAccount.loading ? (
            t("loading")
          ) : props.bankAccount.data.billing_accounts.length > 0 ? (
            <Button
              className="btn-withdraw-modal"
              type="submit"
              disabled={props.withDraw.buttonDisable}
            >
              {props.withDraw.loadingButtonContent != null
                ? props.withDraw.loadingButtonContent
                : t("submit")}
            </Button>
          ) : (
            <Button className="btn-withdraw-modal" disabled>
             {t("submit")}
            </Button>
          )}
        </Form> */}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
  withDraw: state.withDraw.sendWithDraw,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(WithDrawModelSec));
