import {
  FETCH_VIDEOCALL_MODEL_START,
  FETCH_MORE_VIDEOCALL_MODEL_START,
  FETCH_VIDEOCALL_MODEL_SUCCESS,
  FETCH_VIDEOCALL_MODEL_FAILURE,
  GET_VIDEOCALL_MODEL_START,
  GET_VIDEOCALL_MODEL_SUCCESS,
  GET_VIDEOCALL_MODEL_FAILURE,
  STATUS_VIDEOCALL_MODEL_START,
  STATUS_VIDEOCALL_MODEL_SUCCESS,
  STATUS_VIDEOCALL_MODEL_FAILURE,
  SEND_VIDEOCALL_USER_START,
  SEND_VIDEOCALL_USER_SUCCESS,
  SEND_VIDEOCALL_USER_FAILURE,
  FETCH_VIDEOCALL_USER_START,
  FETCH_VIDEOCALL_USER_SUCCESS,
  FETCH_VIDEOCALL_USER_FAILURE,
  GET_VIDEOCALL_USER_START,
  GET_VIDEOCALL_USER_SUCCESS,
  GET_VIDEOCALL_USER_FAILURE,
  STATUS_VIDEOCALL_USER_START,
  STATUS_VIDEOCALL_USER_SUCCESS,
  STATUS_VIDEOCALL_USER_FAILURE,
  JOIN_VIDEOCALL_START,
  JOIN_VIDEOCALL_SUCCESS,
  JOIN_VIDEOCALL_FAILURE,
  END_VIDEOCALL_START,
  END_VIDEOCALL_SUCCESS,
  END_VIDEOCALL_FAILURE,
  FETCH_MORE_VIDEOCALL_USER_START,
  MODIFY_VIDEOCALL_USER_SUCCESS,
  MODIFY_VIDEOCALL_MODEL_SUCCESS,
  VIDEO_CALL_PAYMENT_BY_STRIPE_START,
  VIDEO_CALL_PAYMENT_BY_STRIPE_SUCCESS,
  VIDEO_CALL_PAYMENT_BY_STRIPE_FAILURE,
  FETCH_VIDEO_CALL_VIEW_START,
  FETCH_VIDEO_CALL_VIEW_FAILURE,
  FETCH_VIDEO_CALL_VIEW_SUCCESS,
  FETCH_EVENT_MODEL_LIST_START,
  FETCH_EVENT_MODEL_LIST_SUCCESS,
  FETCH_EVENT_MODEL_LIST_FAILURE,
  FETCH_MORE_EVENT_MODEL_LIST_START,
  START_VIDEOCALL_START,
  START_VIDEOCALL_SUCCESS,
  START_VIDEOCALL_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  modelVideoCall: {
    data: {
      video_calls: [],
      total_video_calls: 0,
    },
    loading: true,
    error: false,
    errorCount: 0,
  },
  modelVideoCallDetails: {
    data: {},
    loading: true,
    error: false,
  },
  modelVideoCallStatus: {
    data: {},
    loading: false,
    error: false,
    buttonContent: null,
    buttonDisable: false,
  },
  sendUserVideoCall: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  userVideoCall: {
    data: {
      video_calls: [],
      total_video_calls: 0,
    },
    loading: true,
    error: false,
    errorCount: 0,
  },
  userVideoCallDetails: {
    data: {},
    loading: true,
    error: false,
  },
  userVideoCallStatus: {
    data: {},
    loading: true,
    error: false,
    buttonContent: null,
    buttonDisable: false,
  },
  joinVideoCall: {
    data: {},
    loading: true,
    error: false,
  },
  endVideoCall: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    buttonContent: null,
  },
  videoCallPaymentByStripe: {
    data: {},
    loading: false,
    error: false,
    buttonContent: null,
    buttonDisable: false,
  },
  videoCallDetails: {
    data: {},
    loading: true,
    error: false,
  },
  eventModelList: {
    data: {
      models: [],
      total: 0,
    },
    loading: true,
    error: false,
    errorCount: 0,
  },
  startVideoCall: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    buttonContent: null,
  },
};

const VideoCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEOCALL_MODEL_START:
      return {
        ...state,
        modelVideoCall: {
          data: {
            video_calls: [],
            total_video_calls: 0,
          },
          loading: true,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_MORE_VIDEOCALL_MODEL_START:
      return state;
    case FETCH_VIDEOCALL_MODEL_SUCCESS:
      return {
        ...state,
        modelVideoCall: {
          data: {
            video_calls: [...state.modelVideoCall.data.video_calls, ...action.data.video_calls],
            total_video_calls: action.data.total_video_calls,
          },
          loading: false,
          error: false,
          errorCount: 0,
        },
      };
    case MODIFY_VIDEOCALL_MODEL_SUCCESS:
      return {
        ...state,
        modelVideoCall: {
          ...state.modelVideoCall,
          data: action.data,
        }
      };
    case FETCH_VIDEOCALL_MODEL_FAILURE:
      return {
        ...state,
        modelVideoCall: {
          data: state.modelVideoCall.data,
          loading: true,
          error: action.error,
          errorCount: state.modelVideoCall.errorCount + 1,
        },
      };
    case GET_VIDEOCALL_MODEL_START:
      return {
        ...state,
        modelVideoCallDetails: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case GET_VIDEOCALL_MODEL_SUCCESS:
      return {
        ...state,
        modelVideoCallDetails: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case GET_VIDEOCALL_MODEL_FAILURE:
      return {
        ...state,
        modelVideoCallDetails: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case STATUS_VIDEOCALL_MODEL_START:
      return {
        ...state,
        modelVideoCallStatus: {
          data: action.data,
          loading: true,
          error: action.error,
          buttonContent: "Loading",
          buttonDisable: true,
        },
      };
    case STATUS_VIDEOCALL_MODEL_SUCCESS:
      return {
        ...state,
        modelVideoCallStatus: {
          data: action.data,
          loading: false,
          error: action.error,
          buttonContent: null,
          buttonDisable: false,
        },
      };
    case STATUS_VIDEOCALL_MODEL_FAILURE:
      return {
        ...state,
        modelVideoCallStatus: {
          data: {},
          loading: false,
          error: action.error,
          buttonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_VIDEOCALL_USER_START:
      return {
        ...state,
        sendUserVideoCall: {
          data: {},
          loading: true,
          error: false,
          errorCode: 0,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SEND_VIDEOCALL_USER_SUCCESS:
      return {
        ...state,
        sendUserVideoCall: {
          data: action.data,
          loading: false,
          error: false,
          errorCode: 0,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SEND_VIDEOCALL_USER_FAILURE:
      return {
        ...state,
        sendUserVideoCall: {
          data: {},
          loading: true,
          error: action.error,
          errorCode: action.error_code,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_VIDEOCALL_USER_START:
      return {
        ...state,
        userVideoCall: {
          data: {
            video_calls: [],
            total_video_calls: 0,
          },
          loading: true,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_MORE_VIDEOCALL_USER_START:
      return state;
    case FETCH_VIDEOCALL_USER_SUCCESS:
      return {
        ...state,
        userVideoCall: {
          data: {
            video_calls: [...state.userVideoCall.data.video_calls, ...action.data.video_calls],
            total_video_calls: action.data.total_video_calls,
          },
          loading: false,
          error: false,
          errorCount: 0,
        },
      };
    case MODIFY_VIDEOCALL_USER_SUCCESS:
      return {
        ...state,
        userVideoCall: {
          ...state.userVideoCall,
          data: action.data,
        }
      };
    case FETCH_VIDEOCALL_USER_FAILURE:
      return {
        ...state,
        userVideoCall: {
          data: state.userVideoCall.data,
          loading: true,
          error: action.error,
          errorCount: state.userVideoCall.errorCount + 1,
        },
      };
    case GET_VIDEOCALL_USER_START:
      return {
        ...state,
        userVideoCallDetails: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case GET_VIDEOCALL_USER_SUCCESS:
      return {
        ...state,
        userVideoCallDetails: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_VIDEOCALL_USER_FAILURE:
      return {
        ...state,
        userVideoCallDetails: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case STATUS_VIDEOCALL_USER_START:
      return {
        ...state,
        userVideoCallStatus: {
          data: action.data,
          loading: true,
          error: false,
          buttonContent: "Loading",
          buttonDisable: true,
        },
      };
    case STATUS_VIDEOCALL_USER_SUCCESS:
      return {
        ...state,
        userVideoCallStatus: {
          data: action.data,
          loading: false,
          error: false,
          buttonContent: null,
          buttonDisable: false,
        },
      };
    case STATUS_VIDEOCALL_USER_FAILURE:
      return {
        ...state,
        userVideoCallStatus: {
          data: {},
          loading: false,
          error: action.error,
          buttonContent: null,
          buttonDisable: false,
        },
      };
    case JOIN_VIDEOCALL_START:
      return {
        ...state,
        joinVideoCall: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case JOIN_VIDEOCALL_SUCCESS:
      return {
        ...state,
        joinVideoCall: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case JOIN_VIDEOCALL_FAILURE:
      return {
        ...state,
        joinVideoCall: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case END_VIDEOCALL_START:
      return {
        ...state,
        endVideoCall: {
          data: action.data,
          loading: true,
          error: action.error,
          buttonDisable: true,
          buttonContent: "Loading",
        },
      };
    case END_VIDEOCALL_SUCCESS:
      return {
        ...state,
        endVideoCall: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          buttonContent: null,
        },
      };
    case END_VIDEOCALL_FAILURE:
      return {
        ...state,
        endVideoCall: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          buttonContent: null,
        },
      };
    case VIDEO_CALL_PAYMENT_BY_STRIPE_START:
      return {
        ...state,
        videoCallPaymentByStripe: {
          data: action.data,
          loading: true,
          error: false,
          buttonContent: "Loading",
          buttonDisable: true,
        },
      };
    case VIDEO_CALL_PAYMENT_BY_STRIPE_SUCCESS:
      return {
        ...state,
        videoCallPaymentByStripe: {
          data: action.data,
          loading: false,
          error: false,
          buttonContent: null,
          buttonDisable: false,
        },
      };
    case VIDEO_CALL_PAYMENT_BY_STRIPE_FAILURE:
      return {
        ...state,
        videoCallPaymentByStripe: {
          data: {},
          loading: false,
          error: action.error,
          buttonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_VIDEO_CALL_VIEW_START:
      return {
        ...state,
        videoCallDetails: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_VIDEO_CALL_VIEW_SUCCESS:
      return {
        ...state,
        videoCallDetails: {
          data: action.data,
          loading: false,
          error: false,
        }
      }
    case FETCH_VIDEO_CALL_VIEW_FAILURE:
      return {
        ...state,
        videoCallDetails: {
          data: {},
          loading: false,
          error: action.error,
        }
      };
    case FETCH_EVENT_MODEL_LIST_START:
      return {
        ...state,
        eventModelList: {
          data: {
            models: [],
            total: 0,
          },
          loading: true,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_MORE_EVENT_MODEL_LIST_START:
      return state;
    case FETCH_EVENT_MODEL_LIST_SUCCESS:
      return {
        ...state,
        eventModelList: {
          data: {
            models: [...state.eventModelList.data.models, ...action.data.models],
            total: action.data.total_models_count,
          },
          loading: false,
          error: false,
          errorCount: 0,
        },
      };
    case FETCH_EVENT_MODEL_LIST_FAILURE:
      return {
        ...state,
        eventModelList: {
          data: state.eventModelList.data,
          error: action.error,
          loading: false,
          errorCount: state.eventModelList.errorCount + 1,
        },
      };
    case START_VIDEOCALL_START:
      return {
        ...state,
        startVideoCall: {
          data: action.data,
          loading: true,
          error: action.error,
          buttonDisable: true,
          buttonContent: "Loading",
        },
      };
    case START_VIDEOCALL_SUCCESS:
      return {
        ...state,
        startVideoCall: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          buttonContent: null,
        },
      };
    case START_VIDEOCALL_FAILURE:
      return {
        ...state,
        startVideoCall: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          buttonContent: null,
        },
      };
    default:
      return state;
  }
};

export default VideoCallReducer;

